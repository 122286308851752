const setFpCookies = async (context, attempt) => {
  if (attempt > 10) {
    return
  }

  if (!window.FingerprintJS) {
    setTimeout(async () => {
      await setFpCookies(context, attempt + 1);
    }, 1000);
    return
  }

  const fpPromise = await window.FingerprintJS.load();
  if (!fpPromise) {
    return
  }

  const response = await fpPromise.get();
  if (!response) {
    return
  }

  const visitorId = response?.visitorId;
  if (!visitorId) {
    return
  }

  context.$cookies.set('fp', visitorId);
}
const setScript = async (context) => {
  const linkScript = "/js/fingerprintjs.min.js";

  const elementScript = document.createElement("script");
  elementScript.type = 'text/javascript';
  elementScript.src = linkScript;
  document.head.appendChild(elementScript);

  await setFpCookies(context, 0);
}

export default async (context) => {
  setTimeout(async () => {
    await setScript(context);
  }, 20000)
}

export default function ({req, res, redirect}) {
  if (process.server) {
    const path = String(String(req.url || '').split('?')?.[0] || '').split("#")?.[0] || "";
    const pathLowerCase = String(path || '').toLowerCase();

    if (path === pathLowerCase) {
      return req.url;
    }

    const otherString = String(req.url || '').replace(path, '');
    return redirect(301, [pathLowerCase, otherString].join(''));
  }
}

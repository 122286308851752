//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectedService from "~/components/account-shop/SelectedService.vue";
import SelectedOffer from "~/components/account-shop/SelectedOffer.vue";
import DialogOffer from "~/components/account-shop/DialogOffer.vue";
import ReadyNumberConfirmModal from "~/components/profile/LanguagesList/ready-numbers/ReadyNumberConfirmModal.vue";

export default {
  name: "ReadyNumbers",
  components: {ReadyNumberConfirmModal, DialogOffer, SelectedOffer, SelectedService},
  data() {
    return {
      servicesList: [],
      offersList: [],

      selectedService: null,
      selectedOffer: null,

      isLoadServicesList: true,
      isLoadOffersList: true,
      isBuySelectedOffer: false,
      isOpenConfirmModal: false,
      isOpenOfferModal: false
    }
  },

  methods: {
    changeSelectedService: async function (val) {
      this.selectedService = val;
      await this.getOffersList();
    },

    getOffersList: async function () {
      const filter = [
        Boolean(this.selectedService) ? `service=${this.selectedService.external_id}` : null
      ].filter((t) => !!t).join("&");

      const res = await this.$api.agent.transport.get(`/ready-accounts/offers-list?${filter}`).then((res) => {
        return res.data || []
      }).catch(() => {
        return []
      });
      this.offersList = res;
      this.isLoadOffersList = false;
    },

    getServicesList: async function () {
      this.servicesList = await this.$api.agent.transport.get('/ready-accounts/services-list').then((res) => {
        return res.data
      }).catch(() => {
        return []
      });
      this.isLoadServicesList = false;
    },

    openConfirmModal(offer) {
      if (this.isLoadingUser) {
        return;
      }
      if (!this.isAuthUser) {
        this.$router.push(this.localePath('/authorization?goback=1'))
        return
      }
      this.isOpenConfirmModal = true;
      this.selectedOffer = offer;
    },
    openOfferModal() {
      this.isOpenConfirmModal = false;
      this.isOpenOfferModal = true;
    },

    closeOffer: function () {
      this.selectedOffer = null;
      this.isBuySelectedOffer = false;
      this.isOpenOfferModal = false;
    },
  },

  computed: {
    isAuthUser: function () {
      return Boolean(this.$store.state.user?.user?.id)
    },
  },

  mounted: async function () {
    await this.getServicesList();
    await this.getOffersList();
  },
}

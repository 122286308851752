const downloadFile = async ({linkFile, $cookies }) => {

  const file = await fetch(linkFile, {
    headers: {
      "Authorization": `Bearer ${ $cookies.get("authToken") }`
    }
  })

  const fileBlob = await file.blob();

  const fileName = (file?.url || "").split("/").pop();

  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(fileBlob);
  link.download = fileName;

  document.body.appendChild(link);

  link.click();
}
const createDownloadFile = async ({ content, fileName }) => {
  const blob = new Blob([content], {type: 'text/plain'});

  const link = document.createElement('a');
  link.download = fileName;
  link.href = window.URL.createObjectURL(blob);
  link.click();
}


export {
  downloadFile,
  createDownloadFile
}

export const state = () => ({
  index: 0
})

export const mutations = {
  updateIndex: function (state) {
    state.index = state.index + 1;
  }
}



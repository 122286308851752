//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ReadyNumberConfirmModal",
  emits: ['onClose', 'okHandler'],
  methods: {
    close() {
      this.$emit('onClose')
    },

    okHandler() {
      this.$emit('okHandler')
    }
  },
  computed: {
    message() {
      return this.$t('rentNumbers.readyNumbers.message', {link: this.localePath('/faq')})
    }
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {required, minLength, email} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      isAgree: false,
      isLoading: false,
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },

  props: {
    formTitle: {
      type: String,
      default: 'feedbackForm.title',
    },

    requestType: {
      type: String,
      default: 'обратная связь',
    }
  },

  computed: {
    isRecaptcha: function () {
      return Boolean(Number.parseFloat(this.$store?.state?.appState?.config?.useReCaptchaForForms || 0))
    }
  },

  validations: {
    form: {
      name: {required, minLength: minLength(2)},
      email: {required, email},
      message: {required, minLength: minLength(6)},
    },
  },
  methods: {
    async onSubmitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return null;
      }

      this.isLoading = true;
      let formNew = this.form;

      if (this.isRecaptcha) {
        formNew['g-recaptcha-response'] = await this.$recaptcha.getResponse().then((res) => {
          return res
        }).catch(() => {
          return ""
        });
        await this.$recaptcha.reset();
      }

      const data = await this.$api.agent.transport
        .post("form-api/send", {type: this.requestType, nophone: "1", ...formNew})
        .catch((err) => {
          this.$toast.error(
            err?.response?.data?.[0]?.message ||
            this.$t("feedbackForm.sendError"),
            {}
          );
        });
      if (data) {
        this.$toast.success(this.$t("feedbackForm.applicationIsAccepted"));
      }
      this.$gtm.push({event: 'feedback'});
      this.form = {name: "", email: "", message: ""};
      this.$v.$reset();
      this.isLoading = false;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";
import ArticleCard from "~/cms/blocks/last-articles-block/ArticleCard.vue";

export default BaseCmsBlock.extend({
  name: "LastArticlesBlock",

  data: function () {
    return {
      articles: this.extras?.articles || [],
    };
  },

  computed: {
    buttonLabel: function () {
      return this.$t('partner.activations_history.load_more')
    },
  },

  components: {
    ArticleCard
  }
});

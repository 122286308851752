function ymGoalCheck({ym_goal, ym_number} = {}) {
  if (ym_goal && ym_number && typeof ym !== "undefined") {
    ym(ym_number, 'reachGoal', ym_goal);

    // console.log(ym_goal, 'reachGoal is ok')
    return null;
  }

  // console.log(ym_goal, 'reachGoal is not ok')
}

function ymSendUserInfo({ym_number, userId, userEmail} = {}) {
  if (ym_number && userId && userEmail && typeof ym !== "undefined") {
    ym(ym_number, 'userParams', {
      email: userEmail,
      UserID: userId,
    });
    // console.log(userId, userEmail, 'userParams is ok');
    return null;
  }

  // console.log(userId, userEmail, 'userParams is not ok');
}

function ymSendUserID({ym_number, userId} = {}) {
  if (ym_number && userId && typeof ym !== "undefined") {
    ym(ym_number, 'setUserID', String(userId));

    // console.log(userId, 'setUserID is ok');
    return null;
  }

  // console.log(userId, 'setUserID is not ok');
}

export {
  ymGoalCheck,
  ymSendUserInfo,
  ymSendUserID
}

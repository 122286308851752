export const state = () => ({
  lastList: [],
  isLoadLastList: false,
})

export const mutations = {
  changeLoadLastList (state, val) {
    state.isLoadLastList = val;
  }
}
export const actions = {
  checkLastOrders: async function (params) {
    if (params.state?.isLoadLastList) {
      return
    }

    const isUserAuth = Boolean(params?.rootState?.user?.user?.id);
    if (!isUserAuth) {
      return
    }

    params.commit('changeLoadLastList', true);

    const commerceOrders = await this.$api.agent.transport.get('/users/commerce-orders').then((res) => {
      return res.data || []
    }).catch(() => {
      return []
    });
    if (commerceOrders.length <= 0) {
      return
    }

    commerceOrders.map((order) => {
      this.$yandexCommerce('purchase', {
        "purchase": {
          "actionField": {
            "id": order?.id
          },
          "products": [
            {
              "name": order?.payment_method_name,
              "price": order?.cost,
              "quantity": 1,
            }
          ]
        }
      });
    });

    const body = {
      order_ids: commerceOrders.map((t) => t.id)
    };
    const res = await this.$api.agent.transport.post('/users/commerce-orders-save', body).then(() => {
      return true
    }).catch(() => {
      return false
    });

    params.commit('changeLoadLastList', false);
  }
}

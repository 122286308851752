export default {
  header: {
    profile: "Perfil",
    loginButton: "Iniciar Sesión",
    registrationBtn: "Sign Up",
    feedback: "Comentarios",
    language: "Idioma",
    darkTheme: "Tema oscuro",
    languageToggle: {
      en: "Inglés",
      ru: "Ruso",
      cn: "Chino",
      tr: "Turco",
      es: "Español",
      hi: "Hindi"
    }
  },
  profileNavMenu: {
    goToProfile: "Perfil",
    activeNumber: "Números recibidos",
    queryStatistics: "Estadísticas de consulta",
    balanceHistory: "Historial de saldos",
    tariffs: "Tarifas",
    settings: "Ajustes",
    balance: "Saldo",
    topupBalance: "Saldo de recarga",
    topupAlert: "",
    logout: "Cerrar Sesión",
    referralProgram: "Programa de referencias",
    saleAgreement: "Venta de licencias",
  },
  profile: {
    activeNumbers: {
      title: "Números recibidos",
      noActiveNumbers: 'Sin números activos',
      serverError: 'Error del Servidor',
      status: "Estado",
      waitingForSms: "Esperando Sms",
      time: "Tiempo",
      banned: "En un bloque?",
      cancel: "Cancelar",
      message: "El código aceptado y pagado. Ingrese el código por favor",
      messageMultipleSms: 'En el tiempo restante, puede obtener nuevos códigos del servicio de forma gratuita',
      blockModalMessage1: '¿Este número fue bloqueado en el lado del servicio?<br /> Háganos saber al respecto y eliminaremos el número de los resultados de búsqueda',
      blockModalMessage2: 'Los fondos se devolverán instantáneamente al saldo.<br /> Intenta comprar un nuevo número en el mismo país o en otro.',
      blockBtn: 'Sí, el número está bloqueado',
      moreSms: "Más Sms",
      availableIn: "Disponible en: ",
      waitingSms: "Esperando nuevos SMS"
    },
    queryStatistics: {
      title: "Estadísticas de Consulta",
      noQuery: "Sin Consulta",
      buyFor: "Comprar por"
    },
    balanceHistory: {
      title: " Historial de Saldos",
      outgoing: "Saliente",
      incoming: "Entrantes",
      showMore: "Mostrar Más",
      noHistory: " Sin historia",
      numberOrder: "Pago por factura №",
    },
    tariffs: {
      title: 'Tarifas',
    },
    settings: {
      title: "Ajustes",
      sound: "Reproducir sonido al recibir el código",
      infoAboutServices: "Mostrar información sobre compras activas",
      twoFactorEnabled: "Autenticación de dos factores",
      infoAboutBalance: "Umbral de saldo más bajo para notificaciones por correo electrónico",
      apiKey: "Clave API",
      message: "Genere una clave API para interactuar con el sitio utilizando el protocolo API",
      buttonUpdate: "Actualizar Clave de API",
      updateError: "Error de Actualización",
      settingsChanged: "Ajustes cambiados",
      errorChangingSettings: "Error al cambiar la configuración",
      phoneNumber: "Su número de teléfono",
      playceholderPhone: "Ingrese su número",
      refParams: {
        refLink: "Informe del programa de referencias:",
        refOptions: "Opciones del Programa de Referencias:",
      },
    },
    myPersonalInformation: {
      title: "Sus datos personales",
      yourEmail: "Su correo electrónico",
      yourName: "Su nombre",
    },
    referralProgram: {
      title: "Programa de referencias",
      referralLinkTitle: "Enlace de referencia",
      buttonCopy: "Copia",
      buttonDownload: "Descargar",
      topUpAmount: "Gastado por sus referencias",
      youHaveAlreadyReceived: "Pagos de referencia recibidos",
      awaiting: "Saldo restante de referencias",
      derived: 'De ellos - derivados',
      detailing: "Informe del sistema de referencias",
      formTitle: "Envíe una solicitud",
      formButton: "Solicitud de Retiro",
      participatePromotion: "Participa en la promoción",
      earnedYourReferrals: "Ganado por sus referencias"
    }
  },
  profilePay: {
    sectionBalanceReplenishment: {
      title: "Reposición de saldo",
      cardButtonSelect: "Seleccione",
      paymentTitle: "Pago",
      paymentMessage: "Siempre realice un pago a través de la interfaz del sitio haciendo clic en el botón Pagar, solo entonces transferirá dinero a los detalles correctos",
      paymentMessageAlipay: "El servicio solo admite usuarios verificados en China continental. Puede probar un método para todos los usuarios de AliPay a continuación.",
      paymentPlaceholder: "Ingrese la cantidad *",
      paymentButton: "Paga",
      paymentErrorAmount: "El monto de la recarga debe ser mayor que 0",
      payComment: "Comisión por reposición",
      plasticCard: "Tarjetas bancarias: solo Rusia",
      minAmount: "Monto mínimo de pago {Minprecio} ₽",
      minAmountMessage: "Monto mínimo de pago {Minprecio} ₽",
      payMessageForPayeer: "El método de reabastecimiento seleccionado, grizzlysms.com los servicios son proporcionados por THREE TWO TWO LTD. Para continuar, debe aceptar los documentos que se describen a continuación.",
      payMessageForPaypalych: "Tarjetas bancarias: solo Rusia  🇷🇺 (alt)",
      som: 'Tarjetas bancarias: en todo el mundo-método №2',
      payMessageForNut: "Tarjetas bancarias: en todo el mundo-método №3",
      stripe: "Tarjetas bancarias: en todo el mundo-método №1 (Raya)",
      bankCardsTemplateRussia: "Tarjetas bancarias: solo Rusia  🇷🇺",
      payMessageForTether: "Importante: transfiere exactamente la cantidad que te indicará el sistema de pago. De lo contrario, los fondos no se acreditarán y el reembolso costará 10.",
      alipayTitle: "Para pagar con AliPay escanee el código QR:",
      alipayText: "...y escríbanos su dirección de correo electrónico de registro y envíe la captura de pantalla del pago al chat en vivo (↘).<br/>Su saldo no se puede recargar automáticamente. Por favor escríbanos.<br/>El pago mínimo es de 10 RMB.",
      paymentMessageForConvertor: "Importe a pagar, {moneda}:",
      paymentMessageForConvertorWithCommission: "Importe a pagar, incluida la comisión, {moneda}:",
      willBeCredited: "Se acreditará, {moneda}:",
      messageForConverterDollars: "Aceptamos dólares y los convertimos en rublos a la tasa: <span> {rate} </span> ₽ por dólar.",
      getNumbersFor: 'Obteniendo el número para {nombre}',
      usdHelpMessage: 'La información se proporciona como referencia. Considere la cantidad indicada en la interfaz del instrumento de pago como una prioridad. Debido a las fluctuaciones del tipo de cambio, los montos pueden variar ligeramente.'
    },
    aliPay: {
      title: "AliPay y QQpayments",
      messageStep1: "1. Vaya al enlace a continuación, elija una cantidad y pague por ella:",
      messageStep2: "2. ingrese el código de pago para agregar dinero a su saldo de Grizzly SMS:",
      send: "Send",
      couponNotFound: "Cupón no encontrado",
      placeholderPassword: "Ingrese el código para agregar",
      waitingForRedirectPayment: "Espere la transición al sitio para realizar un pago",
      paymentCodeNotCorrect: "El código de pago no es correcto",
      buttonGetLink: "Pague a través de AliPay o QQ Obtenga un código",
    },
    cryptoPayments: {
      label: "Pagos criptográficos"
    }
  },
  servicesList: {
    "Выбор_страны": "Selección de país",
    "Выбор_страны_для": "Selección de país para {name}",
    title: "Seleccione país",
    titlePopular: "Países populares",
    chooseAnother: "Elige otro",
    countryInputPlaceholder: "Seleccione país",
    selectedLable: " Seleccione",
    searchInputPlaceholder: "Búsqueda de Servicio",
    buttonWaiting: "Esperando...",
    buttonBuy: "Obtener",
    buttonRent: "Alquiler",
    message: 'Actualmente no hay números disponibles para el país seleccionado',
    buttonUpdate: 'Actualización',
    quantity: 'cantidad',
    quantityFew: 'pocos',
    numberTemporarilyUnavailable: "Disponible solo por API",
    orderFrom100numbers: "Ordene a partir de 100 números a través del servicio de soporte",
    labelCountryHighPercentDelivery: "Países con un alto % de entrega",
    labelSelectOtherCountry: "Elige otro país",
    showMore: "Mostrarmás",
    from: 'desde',
    retail: 'comercio minorista',
    authRetail: 'su precio',
    wholesale: 'venta al por mayor',
    getItFor: "Consíguelo por",
    telegramMessagePrice: 'Wholesale prices info:',
    telegramMessageBot: 'Aplicación Telegram Mini:',
    telegramMessageNews: 'Noticias de servicio:',
    scrollBtn: 'Todos los servicios y países',
    requestNewServiceForm: {
      title: 'Ofrezca un servicio',
      subTitle: "¿No encontró el servicio adecuado? Cuéntanos más sobre esto a continuación y solucionaremos la situación. <br /> Y en este momento ofrecemos usar la sección",
      anyother: 'Otros',
      dataAboutSite: 'Dirección del sitio web *',
      smsMessage: 'Texto SMS *',
      countryService: 'País deseado (no es necesario)',
      price: 'Número precio (no es necesario)',
      btnSend: 'Enviar',
      backToList: 'A la lista',
      offerServiceBtn: "Ofrezca un servicio",
    },
    linkToAccount: {
      btnMsg: "{Nombre de cuenta} cuentas",
      text: "* Trabajando con ready-made {Nombre de cuenta} las cuentas requieren conocimientos de PC; el conjunto de países disponibles difiere del conjunto de países para el autorregistro de cuentas"
    }
  },
  languagesList: {
    "Выбор_сервиса": "Selección de servicios",
    "Поиск_по_сервисам": "Búsqueda por servicios",
    "Выберите_услугу": "Elige un servicio",
    "Поиск_по_странам": "Búsqueda por país",
    "Успешная_покупка_номера": "El número se ha comprado correctamente. Pronto aparecerá en tu \"Números recibidos\"",
    serviceNotFound: 'Servicio no encontrado',
    repeatMsg: 'Esperen < {min} min',
    repeatModal: {
      attempt: 'Intento',
      from: 'de',
      "Долгое_ожидание": '¿Una larga espera?',
      "Предложение_ускорить_поиск": 'Intentemos encontrar una habitación más cara. Los fondos se debitarán definitivamente solo después de recibir un SMS. Siempre puedes cancelar el número o no ingresarlo en la solicitud de servicio. Cuanto mayor sea el recargo, mayor será la posibilidad de encontrar una habitación más rápido.',
      lowBalanceMessage: 'Fondos insuficientes',
    },
    errorMessages: {
      "NO_NUMBERS": "El número no está disponible. Inténtalo de nuevo"
    }
  },
  blog: {
    title: 'Blog',
    buttonDetails: "Más detalles",
    pageNumber: "Página №",
    tags: 'Etiquetas',
    recentPost: 'Publicación reciente',
    readMore: 'Artículos similares',
    getNumberFor: "Obtener número para"
  },
  footer: {
    title: "El mejor servicio para recibir mensajes SMS en línea",
    userAgreement: "Acuerdo de usuario",
    referralProgram: "Programa de referencias",
    returnsPolicy: "Política de devoluciones",
    personalInformation: "Información personal",
    messageDisabledRussianNumbers: "Los números de teléfono de Rusia no están disponibles para la venta",
    footerDisabledRussianNumbers: "Los números de suscriptor de los operadores móviles rusos no se proporcionan para uso temporal debido a los requisitos de la Ley Federal \"Sobre Comunicaciones\" de fecha 07.07.2003 N 126-FZ y también sobre la base del subpárrafo g del párrafo 5 de las Reglas para la gestión centralizada de la red pública de comunicaciones, aprobadas por Decreto del Gobierno de la Federación de Rusia de fecha 12 de febrero de 2020 N 127 \"Sobre la aprobación de las Normas para la gestión centralizada de la red pública de comunicaciones\""
  },
  registration: {
    title: 'Registro',
    registrationSuccessMessage: "Usuario registrado con éxito! <br/> Se ha enviado un enlace para confirmar el registro al correo",
    nameInputPlaceholder: 'Nombre *',
    emailInputPlaceholder: 'Correo electrónico *',
    passwordInputPlaceholder: 'Contraseña *',
    passwordConfirmInputPlaceholder: 'Confirmar Contraseña *',
    buttonRegistration: 'Registro',
    buttonRegistrating: 'Esperando...',
    passwordsMismatchMessage: "Discrepancia de contraseña",
    backHome: 'Volver página de inicio',
    loginWithSocialNetworks: "Iniciar sesión con redes sociales:",
    orRegistrartion: "o regístrate",
    iAgree: "Coincido",
    privacyPolicy: "con datos personales y política de cookies",
    userAgreement: "con oferta pública",
    alreadyHaveAccount: "¿Ya tiene una cuenta??",
    login: "Iniciar Sesión",
    messageSuccessConfirmRegistration: "Usuario registrado con éxito",
  },
  authorization: {
    title: "Autorización",
    emailInputPlaceholder: 'Correo electrónico',
    passwordInputPlaceholder: 'Contraseña',
    tokenInputPlaceholder: 'El código',
    buttonRegistration: 'Autorización',
    buttonRegistrating: 'Esperando...',
    or: 'o',
    newUser: '¿Eres un usuario nuevo??',
    registration: 'Registro',
    forgotPassword: 'Olvidé mi contraseña?'
  },
  resetThePassword: {
    title: "Restablecer la contraseña",
    buttonReset: 'Restablecer la contraseña',
    buttonWaiting: 'Esperando...',
    emailInputPlaceholder: 'Correo electrónico',
    message: "Se ha enviado un enlace para restablecer su contraseña a su correo electrónico. Por favor revise su bandeja de entrada y siga las instrucciones."
  },
  errorsValidationForms: {
    requiredForFill: "Requerido para el llenado",
    emailErrorMessage: "Correo electrónico no válido",
    requaredLengthMessage: "Número mínimo de caracteres:"
  },
  advantagesBlock: {
    title: "Nuestras ventajas"
  },
  aboutBlock: {
    showMoreBtn: "Más detalles",
    rollUp: "Enrollar",
  },
  serviceCard: {
    availableNumbers: "Números Disponibles",
    price: "Precio",
    buyNow: "Comprar",
    emptyCountryListMessage: "No hay números disponibles para el servicio seleccionado.<br /> Por favor, inténtelo de nuevo más tarde"
  },
  feedbackForm: {
    title: "Comentarios",
    namePlaceholder: "Nombre *",
    emailPlaceholder: "Correo Electrónico *",
    messagePlaceholder: "Mensaje *",
    iAgree: "Coincido",
    privacyPolicy: "con datos personales y política de cookies",
    userAgreement: "oferta pública",
    buttonSend: 'Enviar',
    buttonRegistrating: 'Esperando...',
    sendError: "Enviar Error",
    applicationIsAccepted: "Su solicitud es aceptada"
  },
  copyToBuffer: {
    success: "Texto copiado correctamente",
    error: "Error de copia",
  },
  breadcrumbs: {
    homePage: "Página de Inicio",
    blog: "Blog",
  },
  confirm: {
    passwordReset: "Restablecimiento de contraseña",
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirmar contraseña",
    confirm: "Confirmar",
    authorization: "Autorización",
    notMatch: "La contraseña no coincide",
    changedSuccessfully: "Contraseña cambiada con éxito",
  },
  errorPage: {
    pageNotFound: 'Página No Encontrada',
    somethingWentWrong: 'Algo salió mal',
    backHomepage: 'Volver página de inicio'
  },
  support: {
    inputSearch: {
      placeholder: "Introduzca una pregunta",
    }
  },
  successPayment: {
    successMsg: "El pago se realizó correctamente",
    waitMsg: "El pago está en progreso",
    backHomepage: 'Volver página de inicio'
  },
  searchPage: {
    placeholder: 'Buscar...',
    showMore: "Mostrar más",
    nothingFoundMsg: "No se encontró nada para su solicitud",
    'Сервисы': 'Servicios',
    'Статьи': "Artículos",
    'Страницы': "Páginas",
    'Все результаты': " Todos los resultados",
  },
  common: {
    "downloadFile": "Descargar archivo"
  },
  lastArticlesBlock: {
    title: "Blog",
    textBtn: "Nuestro blog",
  },
  reviewsBlock: {
    title: 'Qué Dicen Los Clientes Sobre Nosotros',
    addReviewBtn: 'Añadir una reseña',
    yourName: 'Su nombre',
    messageTextarea: 'Por favor, añada su opinión',
    sendReview: 'Enviar reseña',
    success: 'La reseña se ha enviado correctamente',
    validNameMsg: 'Ingrese su nombre',
    validMsg: 'Ingrese su reseña',
  },
  currencyToggle: {
    tooltipCurrency: 'Los precios en USD se muestran como referencia. Las operaciones con saldo se realizan solo en RUB'
  },
  pagination: {
    showing: 'mostrando',
    of: 'de',
  },
  additionalCashbackAccrual: {
    message: "<span>+10%</span> reembolso en efectivo a su saldo en caso de que <span>1st</span> recarga desde <span>{{balance}}</span> !",
    buttonCancel: "No, gracias",
    buttonPayment: "Saldo de recarga"
  },
  countries: {
    title: 'Lista de países disponibles',
    countries: 'Países',
    popularServices: 'Servicios populares',
    anotherServices: 'Elegir servicio'
  },
  botLinkPage: {
    question: '¿Realmente desea conectar su cuenta en el grizzlysms.com sitio web que usa la cuenta de telegram <span> {userName}</span> para el bot?',
    ok: 'Sí',
    cancel: 'Cancelar',
    success: "Cuenta de Telegram conectada correctamente",
    alreadyHaveBot: 'Ya tienes un bot de telegram vinculado',
  },
  errors: {
    err1: 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.'
  },
  partnerPage: {
    becomePartner: "Conviértase en socio",
    popUp: {
      title: "Conviértase en socio",
      yourContactPlaceholder: "Su contacto (Telegram, WeChat, WhatsApp, Correo electrónico, otro)",
      countryPlaceholder: "Países de \"origen\" de las tarjetas SIM",
      equipmentPlaceholder: "Su Equipo",
      equipmentPortsPlaceholder: "Número de puertos de hardware",
      aboutPlaceholder: "¿Qué más necesitamos saber sobre usted??",
      send: "Enviar",
      accept1: "Coincido",
      accept2: "con la política de privacidad y el uso de los datos solicitados",
      errorForm: "Se ha producido un error, inténtelo de nuevo más tarde",
      successForm: "Solicitud enviada correctamente",
    }
  },
  partnerFormBlock: {
    title: "Conviértase en socio",
    contactTitle: "Telegrama",
    contactPlaceholder: "Su contacto preferido",
    countrySimCard: "Países de números de teléfono",
    countrySimCardPlaceholder: "Separados por comas",
    yourEquipment: "Modelos de módems GSM",
    yourEquipmentPlaceholder: "Cuéntame en detalle",
    countPort: "Cantidad de puertos COM (16 o más)",
    countPortPlaceholder: "Especificar en números",
    message: "¿Qué más necesitamos saber sobre usted??",
    messagePlaceholder: "Describe tu negocio en detalle",
    accountGrizzlySmsEmail: "Cuenta de Grizzly SMS (dirección de correo electrónico que utilizó)"
  },
  partnerHeadingBlock: {
    becomePartner: "Conviértase en socio",
    yieldCalculator: "Calculadora de Rendimiento"
  },
  partnerCalculatorBlock: {
    country: "País",
    priceSim: "Costo de la tarjeta SIM",
    countPort: "Número de puertos en el módem",
    netProfit: "Beneficio",
    netProfit3: "Beneficio durante 3 meses",
    netProfit6: "Ingresos por 6 meses",
    netProfit12: "Ingresos por 12 meses",
  },
  generalSearch: {
    searchByCountry: "Búsqueda por país",
    searchByService: "Búsqueda por servicio",
    title: "Precios de números virtuales para recibir SMS",
    subtitle: "Seleccione el servicio o país que necesita en la tabla a continuación",
    tableHeaderService: "Servicio",
    tableHeaderCountry: "País",
    tableHeaderCurrency: "Precio al por mayor",
    tableHeaderAmount: "Cantidads",
  },
  profileLoyaltyProgram: {
    cashback: {
      title: "Devolución de Dinero",
      week_deposit: "Monto de recarga para la semana actual",
      projectedCashback: "Reembolso previsto",
      tillNextRank: "Hasta que quede el siguiente rango",
      cashbackPercentNextRank: "Devolución de dinero en el siguiente rango",
      rang: "rango"
    },
    history: {
      title: "Historial de participación",
      message1: "Para reposición",
      message2: "devolución de dinero acreditada",
    },
    programConditions: {
      title: "Términos del programa de lealtad",
      rangLeft: "Rango: condición de obtención",
      rangRight: "% devolución de dinero",
      rangNotLeft: "Sin rango: recargas > 0 <1,000 ₽",
      rangNotRight: "sin devolución de dinero",
      message: "El reembolso se acredita a los usuarios en <span>1st day of the week (Monday)</span> según los resultados de la cantidad total de depósitos durante la semana pasada. El porcentaje de reembolso depende del rango alcanzado por los usuarios al final de la semana pasada.",
      replenishment: "reposición",
      cashback: "Devolución de Dinero"
    }
  },
  license: {
    licenseSuccess: 'La licencia ha sido comprada y enviada a su correo electrónico',
    buyKey: 'Compre una llave en GrizzlySMS'
  },
  featuresTextBlock: {
    getIndividualNumber: "Obtener un número privado"
  },
  sortComponent: {
    country: 'Principales países'
  },
  rentNumbers: {
    typeViews: {
      "number_activation": "Activación",
      "rent_number": "Alquiler",
      "readyAccounts": "Cuentas"
    },
    rentInformation: {
      "rental_period": "Periodo de alquiler",
      "rental_duration": "Duración del alquiler",
      "rent": "Alquiler",
      "the_clock": "por hora",
      "for_days": "por día",
      "specify_rental_duration": "Especifique la duración del alquiler",
      "maximum_number_hours": "Número máximo de horas",
      "minimal_number_hours": "Número mínimo de horas",
      "number_successfully_rented": "Número alquilado con éxito",
      "selected_time_not_available": "Número no disponible",
      "available_minimum_rental_period": "Período mínimo de alquiler disponible",
      "available_maximum_rental_period": "Período máximo de alquiler disponible",
    },
    myRentNumbers: {
      "rent_virtual_number": "Alquile un número virtual",
      "active": "Activo",
      "received": "Recibido",
      "returned": "Cancelado",
      "not_found": "No encontrado"
    },
    rentNumberCard: {
      "canceled": "Cancelado",
      "expires_in": "Caduca en",
      "extension": "Extender",
      "messageTitle": "Mensajes",
      "messageMessage": "Aún no han llegado mensajes ni llamadas. Envíe un SMS al número o llame, y la información se mostrará inmediatamente aquí.",
      "successCancel": "Alquiler de número cancelado con éxito",
      "successNumberRenewal": "El alquiler de números se extendió con éxito",
      "rentalPeriodExpired": "Caducado"
    },
    readyNumbers: {
      message: '¡IMPORTANTE! Si inicialmente está comprando cuentas prefabricadas, deberá revisar la información en el <a href="{link}"> "Cuentas"</a> sección sobre el " Soporte (Preguntas FRECUENTES)" página.',
      ok: 'Coincido',
      toFaq: 'Preguntas FRECUENTES',
      cancel: 'Cancelar'
    },
    popUpExtendLease: {
      "extend_number_rental": "Extender el alquiler de números",
      "going_renew_lease_number": "¿Va a renovar su contrato de arrendamiento en un número?",
      "renewal_history": "Historial de renovaciones",
      "date": "Date",
      "price": "Price",
      "term_hours": "Límite de tiempo en horas",
      "number_rental_renewal_period": "Período de renovación del alquiler del número",
      "lease_successfully_extended_until": "El contrato de arrendamiento se extendió con éxito hasta",
      "close": "Cerrar",
      "short_day": "días",
      "short_hours": "hora.",
      "short_minutes": "mín."
    },
    helpPopUp: {
      message: `<h4>Activación del número</h4>
      <p>Si no recibe un SMS con un código dentro de los primeros 20 minutos, puede cancelar el número, el dinero se devolverá a su cuenta en su totalidad.</p>

      <h4>Alquile un número</h4>
      <p>Durante el período seleccionado, siempre tendrá la oportunidad de recibir un número ilimitado de SMS del servicio seleccionado.</p>
      <p>Siempre puede restaurar el acceso a su cuenta registrada!</p>
      <p>Si no recibe un SMS con un código dentro de los primeros 20 minutos, puede cancelar el número, el dinero se devolverá a su cuenta en su totalidad.</p>
      <p>Si no has recibido un SMS y han pasado más de 20 minutos, será imposible cancelar el alquiler.</p>
      <p>Un número vendido en alquiler no se revenderá por el servicio correspondiente al vencimiento del período de arrendamiento..</p>
      <p>El período mínimo de alquiler es de 4 horas, el máximo es de 4 semanas..</p>
      <p>Hay dos opciones para alquilar un número:</p>
      <p>Alquiler completo: en este caso, puede recibir todos los SMS, cuyos servicios se presentan en nuestro catálogo.</p>
      <p>Alquile un número para un servicio específico. En este caso, recibirá SMS solo del servicio que haya elegido.</p>

      <h4>Cuentas</h4>
      <p>¿No quieres perder el tiempo esperando SMS? Puede comprar una cuenta prefabricada creada por nuestros socios. Las cuentas de Telegram, VK y otros servicios suelen estar disponibles.</p>
      <p>Las cuentas de Telegram se proporcionan en 2 formatos: TDATA (una cuenta en este formato puede iniciar sesión en una PC y luego transferirse a un dispositivo móvil) y json+sesión (en este caso necesitará un software especial).</p>
      <p>Los detalles de cómo trabajar con cuentas listas para usar, así como las condiciones de devolución, se describen en la sección de preguntas frecuentes (el botón" Soporte " en el menú del sitio). Asegúrese de leer esta información antes de comprar!</p>
      `
    }
  },
  comments: {
    "add_comment": "Agregar un comentario",
    "comments": "Comentarios",
    "collapse": "Colapso",
    "expand": "Expandir",
    "send_comment_moderation": "Su comentario ha sido enviado para moderación.",
    "ony_auth_user": "Disponible solo para miembros registrados"
  },
  saleAgreement: {
    "title": "Venta de licencias",
    "name": "Nombre",
    "type_po": "Tipo de software",
    "price": "Precio",
    "date_purchase": "Fecha de compra",
  },
  freeNumbersPopUpRegistration: {
    subtitle: "¿La creación de una cuenta con un número gratuito no funcionaba? Regístrese y seleccione un número privado para el servicio — <span>desde $0.01!</span>",
    button: "PRUEBA"
  },
  accountShop: {
    mainSectionInfo: {
      my_paid_accounts_title: "Mis cuentas pagas",
      my_paid_accounts_message: "Aquí puede ver una lista de todas las cuentas compradas y la información de inicio de sesión requerida",
      my_paid_accounts_button: "Cuentas de compra",

      purchasing_ready_accounts_title: "Cuentas de compra",
      purchasing_ready_accounts_message: "Entendemos que no siempre es posible recibir SMS en 20 minutos. En esta sección puede comprar una cuenta ya hecha para cualquier servicio popular.",
      purchasing_ready_accounts_button: "Mis cuentas pagas",
    },
    selectedService: {
      title: "Seleccione servicio",
      search_placeholder: "Búsqueda por nombre de servicio"
    },
    selectedOffer: {
      title: "Seleccionar oferta"
    },
    dialogOffer: {
      title: "Comprar cuenta",
      name_of_product: "Nombre del producto",
      description_of_product: "Descripción del producto",
      characteristics: "Características",
      registration_method: "Método de registro",
      country: "País",
      account_format: "Formato de cuenta",
      availability_cloud_password: "Contraseña de la nube",
      information_about_purchased_product: "Información sobre el producto adquirido",
      download_files: "Descargar archivos",
      buy_more: "Compre más",
      yes: "Sí",
      no: "No",
      minimum_order_quantity: "Cantidad de orden mínima"
    },
    myServices: {
      messageAuth: "Necesitas iniciar sesión en tu cuenta",
      messageEmpty: "Sin cuentas compradas"
    }
  },
  partner: {
    breadcrumbs_title: "Socio",
    documentation: "Documentación",
    activations_history: {
      activations_history: "Historial de activaciones",
      filter: "Filtro",
      export_in: "Exportar a",
      number: "Número",
      load_more: 'Mostrar más',
      total_rows: 'Total: {{count }} filas',
      select_status: 'Seleccionar estado',
      date_activation: 'Fecha de activación',
      date_from: 'Fecha de',
      date_to: 'Fecha hasta',
      ready: '¿Listos?',
      canceled: 'Cancelado',
      activation: "Activación"
    },
    sim_card: {
      sim_card: "Tarjeta SIM",
      port: 'Puerto',
      info: 'Información',
      earned: 'Ganado',
      countServices: 'Servicios de conteo'
    },
    price_regulator: {
      price_regulator: "Regulador de precios",
      select_country_started: "Seleccione un país para comenzar",
      services: "Servicios",
      search_service: "Búsqueda por servicio",
      all_services: "Todos los servicios",
      all_countries: "Todos los países",
      popular_services: "Popular",
      market_price: "Fije el precio de mercado",
      competitors_prices: "Mostrar precios de la competencia",
      pice_successfully_changed: "Precio cambiado con éxito",
    },
    withdrawal_founds: {
      withdrawal_founds: 'Fondos de retiro',
      balance: 'Su saldo',
      order_payments: 'Solicitar un pago',
      currency_type: 'Tipo de moneda',
      withdrawal_method: 'Método de retiro',
      order_status: 'Estado del pedido',
      select_method: 'Seleccione un método',
      select_status: 'Seleccionar estado',
      requisites: 'Requisitos',
      sum: 'Suma',
      currency: 'Moneda',
      date: 'Fecha',
      status: 'Estado',
      nothing_found: 'No se ha encontrado nada',
      amount_in_currency: 'Importe en moneda',
      wallet_card_number: 'Número de billetera/tarjeta',
      comment: 'Comentario',
      not_necessary_placeholder: 'No es necesario',
      all_btn: 'Todos',
      error_required: 'Rellene todos los campos obligatorios',
      success_msg: 'Su solicitud es aceptada',
      warning_message: '<span>IMPORTANT!</span> Asegúrese de ingresar los detalles de pago correctos al crear su solicitud anterior️. La administración de grizzlysms.com no se hace responsable en caso de pérdida de fondos debido a detalles de pago incorrectos.'
    },
    statistic: {
      statistic: 'Estadística de monetización',
      average_SIM: 'Promedio <br/> por SIM',
      top_services: 'Los mejores servicios <br/>durante 24 horas',
      activations_count: 'Recuento de activaciones <br />durante 24 horas',
      last_24_hours: 'Por último <br / >24 horas'
    },
    dialog_competitors_prices: {
      your_price: "Su precio",
      competitors_prices: "Precios de la competencia"
    },
  },
  newPayments: {
    minAmount: 'Monto mínimo de pago:',
    commission: 'Comisión:',
  },
  soft: {
    "submit_application": "Enviar solicitud",
    "search_by_name": "Buscar por nombre",
    "all_services": "Todos<br/>servicios",
    "activations": "Activaciones"
  },
  productLicensesBlock: {
    availableAt: "Disponible en",
  },
  documentationApi: {
    rent: "Alquiler API",
    tables: "Tablas",
    activations: "Activaciones de API",
    expandContent: "Expandir contenido",
    collapseContent: "Contraer contenido",
    successLinkCopy: "Enlace copiado correctamente",
    successApiKeyCopy: "Clave API copiada correctamente",

    select: "Seleccione",
    fillIn: "Rellenar",
    result: "Resultado",
    execute: "Ejecutar",
    urlRequest: "URL de SOLICITUD",
    resultLoading: "Resultado de carga...",
    testMethodNow: "Pruebe este método ahora"
  },
  marketplaceRegistrationPage: {
    pageTitle: "Registro en la tienda",
    formInputs: {
      name: {
        label: 'Inventa un nombre para tu tienda. Quizás cómo lo llames refleje la esencia de tu producto y ayude al comprador a la hora de elegir.',
        placeholder: 'Nombre',
        errorMessage: 'Requerido para completar'
      },
      shopDescription: {
        label: 'Descripción de la Tienda',
        placeholder: 'Cree una descripción de la tienda, resalte su singularidad y características....',
        errorMessage: 'Requerido para completar'
      },
      email: {
        label: 'Tu Correo Electrónico',
        placeholder: 'Por favor, introduzca su dirección de correo electrónico para comentarios...',
        errorMessageForNull: 'Requerido para completar',
        errorMessageNoValid: 'Dirección de correo electrónico no válida'
      },
    },
    formSendButton: 'Registrar una tienda',
    rulesMarket: {
      iAgree: 'Coincido',
      rulesPhrase: 'Grizzlysms.com reglas y regulaciones del mercado'
    },
    offerAgreement: {
      iAccept: 'Acepto',
      offerPhrase: 'acuerdo de oferta'
    },
    toastSuccessMessage: 'Su solicitud ha sido enviada'
  }
}

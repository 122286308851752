export const state = () => ({
  items: [],
});

export const mutations = {
  addItem(state, item) {
    state.items = [
      item,
      ...state.items
    ];
  },
  removeItem: function (state, itemId) {
    let items = [...state.items];
    const index = items.findIndex((t) => t.id === itemId);
    items.splice(index, 1);
    state.items = items;
  },
};

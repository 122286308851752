//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {createDownloadFile} from "~/helpers/getFile";

export default {
  name: 'DialogOffer',
  data: function () {
    return {
      fullOffer: {},

      responseLinks: [],

      quantity: 1,
      multiplicity: 1,

      isSuccessBuy: false,
      isLoadFullOffer: true,
      isShoppingProgress: false
    }
  },

  props: {
    offer: {
      type: Object,
      default: () => {
      }
    },
    isBuy: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    priceValue: function () {
      return this.$convertCurrency(this.offer?.price)
    },
    priceValueQtn: function () {
      return this.$convertCurrency(this.offer?.price * this.quantity)
    },
    priceCurrency: function () {
      const currentCurrency = this.$store?.state?.currency?.currentCurrency;
      return Boolean(currentCurrency === 'usd') ? "$" : "₽";
    },

    serviceName: function () {
      return this.fullOffer?.service?.name
    },
    serviceIcon: function () {
      return this.fullOffer?.service?.icon
    },

    characteristics: function () {
      let list = [];
      list.push({
        label: this.$t('accountShop.dialogOffer.registration_method'),
        value: this.fullOffer.registration_type
      });
      list.push({
        label: this.$t('accountShop.dialogOffer.country'),
        value: this.offer?.country?.name
      });
      list.push({
        label: this.$t('accountShop.dialogOffer.account_format'),
        value: this.fullOffer.format
      });
      list.push({
        label: this.$t('accountShop.dialogOffer.availability_cloud_password'),
        value: this.$t(`accountShop.dialogOffer.${Boolean(this.fullOffer?.cloud_pass) ? 'yes' : 'no'}`)
      });
      return list
    },
  },

  mounted: async function () {
    await this.getOffer();
  },

  methods: {
    getOffer: async function () {
      if (this.isBuy) {
        this.fullOffer = this.offer;
        this.responseLinks = this.offer.result;
        this.isLoadFullOffer = false;
        return
      }

      const res = await this.$api.agent.transport.get(`/ready-accounts/offer-info/${this.offer.id}`).then((res) => {
        return res.data
      }).catch(() => {
        return {}
      });

      this.fullOffer = res;
      this.quantity = res?.min_count || 1;
      this.multiplicity = res?.min_count || 1;
      this.isLoadFullOffer = false;
    },
    close: function () {
      this.$emit("close");
    },

    buyAccount: async function () {
      this.isShoppingProgress = true;
      const res = await this.$api.agent.transport.post('/ready-accounts/buy-offer', {
        "id": this.fullOffer.id,
        "count": this.quantity
      }).then((res) => {
        return res.data
      }).catch((err) => {
        return {error: err.response}
      });
      if (res.error) {
        this.isShoppingProgress = false;
        let errorMessage = res.error?.data?.message;
        this.$toast.error(errorMessage || 'Ошибка сервера');
        return
      }
      this.responseLinks = res;
      this.isSuccessBuy = true;
      this.isShoppingProgress = false;

      this.$emit("successBuy");
    },
    downLoadFiles: async function () {
      this.$store.commit("loading/setLoading", {value: true});

      await createDownloadFile({
        content: JSON.stringify(this.responseLinks),
        fileName: `grizzly-accounts-${ this.offer.offerId || this.fullOffer.offerId }.txt`
      });

      this.$store.commit("loading/setLoading", {value: false});
    },

    _getLangValue: function (value) {
      return value
      const locale = this.$i18n.localeProperties.code;
      return value?.[locale] || value?.en || value?.ru || value || ""
    },


    changeQuantity: function (direction) {
      let quantity = this.quantity;
      if (direction === '-') {
        quantity = quantity - 1;
      }
      if (direction === '+') {
        quantity = quantity + 1;
      }

      if (this.fullOffer.count < quantity) {
        return
      }
      if (this.fullOffer.min_count > quantity) {
        return;
      }

      this.quantity = quantity;
    },
  }
}

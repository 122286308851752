//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CustomToasted",

  computed: {
    items: function () {
      return this.$store.state?.toast?.items || []
    }
  },

  components: {
    Toast: () => import("~/components/common/custom-toasted/Toast.vue")
  }
}

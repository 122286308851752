import MobileDetectLocal from "~/helpers/mobileDetect";
import {viewportDetectBreakpoint, viewportOptions, viewportParseCookie, viewportSetJsCookie} from "~/helpers/viewport";

export const state = () => ({
  headers: {},
  host: '',

  viewport: "",
  isMobile: false,
});

export const mutations = {
  setCurrentHost: function (state, host) {
    state.host = host;
  },
  setHeaders: function (state, headers) {
    state.headers = headers;
  },

  setViewport: async function (state, { headers, app }) {
    let cookie = "";
    let viewport = "";
    if (headers != null) {
      cookie = headers.cookie;
    }
    if (typeof cookie !== "string") {
      cookie = "";
    }

    cookie = viewportParseCookie(cookie)['viewport'];
    viewport = await viewportDetectBreakpoint(viewportOptions, {
      cookie,
      headers
    });

    state.viewport = viewport;
  },
  changeViewport: function (state, viewport) {
    state.viewport = viewport;
  }
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _22f5273a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))
const _1d06b557 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _40d143da = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _8000cdb4 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _50ab60c7 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _974e419e = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _416f37de = () => interopDefault(import('../pages/request-password/index.vue' /* webpackChunkName: "pages/request-password/index" */))
const _61f8b720 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _6e2f2a9a = () => interopDefault(import('../pages/success-payment/index.vue' /* webpackChunkName: "pages/success-payment/index" */))
const _2e15d7bb = () => interopDefault(import('../pages/partner/activations-history/index.vue' /* webpackChunkName: "pages/partner/activations-history/index" */))
const _d6f78fd8 = () => interopDefault(import('../pages/partner/price-regulator/index.vue' /* webpackChunkName: "pages/partner/price-regulator/index" */))
const _cbb01ab6 = () => interopDefault(import('../pages/partner/sim-card/index.vue' /* webpackChunkName: "pages/partner/sim-card/index" */))
const _ba8b8fb2 = () => interopDefault(import('../pages/partner/statistic/index.vue' /* webpackChunkName: "pages/partner/statistic/index" */))
const _03e240d6 = () => interopDefault(import('../pages/partner/withdrawal-funds/index.vue' /* webpackChunkName: "pages/partner/withdrawal-funds/index" */))
const _13cfb40c = () => interopDefault(import('../pages/profile/history/index.vue' /* webpackChunkName: "pages/profile/history/index" */))
const _5582d335 = () => interopDefault(import('../pages/profile/loyalty-program/index.vue' /* webpackChunkName: "pages/profile/loyalty-program/index" */))
const _c49ca4c0 = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _5923c5b2 = () => interopDefault(import('../pages/profile/referral-program/index.vue' /* webpackChunkName: "pages/profile/referral-program/index" */))
const _0532d622 = () => interopDefault(import('../pages/profile/sale-agreement/index.vue' /* webpackChunkName: "pages/profile/sale-agreement/index" */))
const _186aa47e = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _d50e43fe = () => interopDefault(import('../pages/profile/statistics/index.vue' /* webpackChunkName: "pages/profile/statistics/index" */))
const _f6ee24f2 = () => interopDefault(import('../pages/profile/tariffs/index.vue' /* webpackChunkName: "pages/profile/tariffs/index" */))
const _977c90e6 = () => interopDefault(import('../pages/signup/confirm-email.vue' /* webpackChunkName: "pages/signup/confirm-email" */))
const _3f1aa292 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _79d70cff = () => interopDefault(import('../pages/bot-link/_link.vue' /* webpackChunkName: "pages/bot-link/_link" */))
const _502fae5e = () => interopDefault(import('../pages/confirm/_token.vue' /* webpackChunkName: "pages/confirm/_token" */))
const _4b15185f = () => interopDefault(import('../pages/countries/_.vue' /* webpackChunkName: "pages/countries/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ru",
    components: {
      default: _22f5273a
    },
    name: "index___ru"
  }, {
    path: "/cn",
    components: {
      default: _22f5273a
    },
    name: "index___cn"
  }, {
    path: "/tr",
    components: {
      default: _22f5273a
    },
    name: "index___tr"
  }, {
    path: "/es",
    components: {
      default: _22f5273a
    },
    name: "index___es"
  }, {
    path: "/hi",
    components: {
      default: _22f5273a
    },
    name: "index___hi"
  }, {
    path: "/authorization",
    component: _1d06b557,
    pathToRegexpOptions: {"strict":true},
    name: "authorization"
  }, {
    path: "/blog",
    component: _40d143da,
    pathToRegexpOptions: {"strict":true},
    name: "blog"
  }, {
    path: "/partner",
    component: _8000cdb4,
    pathToRegexpOptions: {"strict":true},
    name: "partner"
  }, {
    path: "/profile",
    component: _50ab60c7,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/registration",
    component: _974e419e,
    pathToRegexpOptions: {"strict":true},
    name: "registration"
  }, {
    path: "/request-password",
    component: _416f37de,
    pathToRegexpOptions: {"strict":true},
    name: "request-password"
  }, {
    path: "/search",
    component: _61f8b720,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/success-payment",
    component: _6e2f2a9a,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment"
  }, {
    path: "/partner/activations-history",
    component: _2e15d7bb,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history"
  }, {
    path: "/partner/price-regulator",
    component: _d6f78fd8,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator"
  }, {
    path: "/partner/sim-card",
    component: _cbb01ab6,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card"
  }, {
    path: "/partner/statistic",
    component: _ba8b8fb2,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic"
  }, {
    path: "/partner/withdrawal-funds",
    component: _03e240d6,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds"
  }, {
    path: "/profile/history",
    component: _13cfb40c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history"
  }, {
    path: "/profile/loyalty-program",
    component: _5582d335,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program"
  }, {
    path: "/profile/pay",
    component: _c49ca4c0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay"
  }, {
    path: "/profile/referral-program",
    component: _5923c5b2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program"
  }, {
    path: "/profile/sale-agreement",
    component: _0532d622,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement"
  }, {
    path: "/profile/settings",
    component: _186aa47e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings"
  }, {
    path: "/profile/statistics",
    component: _d50e43fe,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics"
  }, {
    path: "/profile/tariffs",
    component: _f6ee24f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs"
  }, {
    path: "/signup/confirm-email",
    component: _977c90e6,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email"
  }, {
    path: "/blog/:slug",
    component: _3f1aa292,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug"
  }, {
    path: "/bot-link/:link?",
    component: _79d70cff,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link"
  }, {
    path: "/confirm/:token?",
    component: _502fae5e,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token"
  }, {
    path: "/countries/*",
    component: _4b15185f,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all"
  }, {
    path: "/*",
    component: _22f5273a,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

import {joinURL, encodeQueryItem, encodePath} from "ufo";
import {createOperationsGenerator} from "@nuxt/image/dist/runtime";

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    orientation: "or",
    flip: "flip",
    crop: "crop",
    width: "w",
    height: "h",
    fit: "fit",
    dpr: "dpr",
    bri: "bri",
    con: "con",
    gam: "gam",
    sharp: "sharp",
    blur: "blur",
    pixel: "pixel",
    filt: "filt",
    mark: "mark",
    markw: "markw",
    markh: "markh",
    markx: "markx",
    marky: "marky",
    markpad: "markpad",
    markpos: "markpos",
    markalpha: "markalpha",
    background: "bg",
    border: "border",
    quality: "q",
    format: "fm"
  },
  valueMap: {
    fit: {
      fill: "fill",
      inside: "max",
      outside: "stretch",
      cover: "crop",
      contain: "contain"
    }
  },
  joinWith: "&",
  formatter: (key, val) => encodeQueryItem(key, val)
});
export const getImage = (src, {modifiers = {}, baseURL = "/"} = {}) => {
  const generatedParams = operationsGenerator(modifiers);

  const params = generatedParams.split('&')
    .filter(pair => {
      const [key, value] = pair.split('=');

      if (key == 'fm') {
        return false
      };

      return true;
    })
    .map(p => p.replace('=', '-'));

  const normalizedSrc = src.replace('/', '');

  params.unshift(normalizedSrc);

  const formattedParams = params.join('_') + `.${modifiers.format}`;

  return {
    url: joinURL(baseURL, formattedParams)
  };
};
export const state = () => ({
	popUpLoading: false
});

export const mutations = {
	setLoading(state, {name, value}) {
		state[name || "popUpLoading"] = value || false;
	}
};

export const getters = {
	popUpLoading: ({popUpLoading}) => popUpLoading,
}

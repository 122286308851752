export default (props, inject) => {
  const yandexCommerce = function (actionType, information) {
    // Инициализация dataLayer
    window.dataLayer = window.dataLayer || [];

    if (actionType === 'purchase') {
      information.purchase.actionField.goal_id = 86626883;
    }
    let ecommerce = {
      "currencyCode": "RUB",
      ...information
    }


    window.dataLayer.push({"ecommerce": ecommerce});
  }

  return inject("yandexCommerce", yandexCommerce)
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SortComponent",

  props: {
    currentSort: {
      type: String,
      default: '',
    },
  },

  computed: {
    sortItems: function () {
      return [
        {
          label: this.$t('sortComponent.country'),
          sortName: "name",
        },
        {
          label: this.$t('generalSearch.tableHeaderAmount'),
          sortName: "count",
        },
        {
          label: this.$t('serviceCard.price'),
          sortName: "price",
        },
      ];
    }
  },

  methods: {
    changeSort: function (name) {
      let newSortName = this.currentSort;
      if (String(newSortName || '').replace('-', '') !== name) {
        newSortName = ['-', name];
      }
      if (newSortName?.[0] === '-') {
        newSortName = name;
      } else {
        newSortName = ['-', name].join('');
      }
      this.$emit('changeCurrentSort', newSortName);
    },
    getActiveClass: function (name) {
      let className = [];

      const isActive = Boolean(name === String(this.currentSort || '').replace('-', ''));
      if (isActive) {
        className.push('active');
      }
      if (isActive && String(this.currentSort || '')?.[0] === '-') {
        className.push('desk');
      }
      return className.join(' ')
    }
  }
}

export const BASE_URL = process.env.BASE_API_URL;
export const BASE_API_URL = `${BASE_URL}/api/`;
export const userLocaleCookieName = 'x-user-locale';
export const siteKey = process.env.RECAPTCHA_SITE_KEY || '6Ldqo00iAAAAAHBizkjiQFSnyl3e5AHQz2ItSgVr';
export const locales = [
  {
    code: 'ru',
    name: 'Ру',
    file: 'ru-RU.js',
    iso: 'ru-RU',
    domain: process.env.DOMEN_LANG_RU
  },
  {
    code: 'en',
    name: 'En',
    file: 'en-EN.js',
    iso: 'en-US',
    domain: process.env.DOMEN_LANG_OTHER
  },
  {
    code: 'cn',
    name: 'Cn',
    file: 'cn-CN.js',
    iso: 'zh_CN',
    domain: process.env.DOMEN_LANG_OTHER
  },
  {
    code: 'tr',
    name: 'Tr',
    file: 'tr-TR.js',
    iso: 'tr_TR',
    domain: process.env.DOMEN_LANG_OTHER
  },
  {
    code: 'es',
    name: 'Es',
    file: 'es-ES.js',
    iso: 'es_ES',
    domain: process.env.DOMEN_LANG_OTHER
  },
  {
    code: 'hi',
    name: 'Hi',
    file: 'hi-HI.js',
    iso: 'hi_HI',
    domain: process.env.DOMEN_LANG_OTHER
  },
];
import {redirect} from "./redirect"
import {criticalCss} from './assets/scss/components/critical-css';
import {criticalFonts} from './assets/scss/components/critical-fonts';

const DEFAULT_LOCALE = 'en';
const version = new Date().getTime();

export default {
  modern: process.env.NODE_ENV === 'production',
  server: {
    port: process.env.APP_PORT || 57372,
    host: '0.0.0.0',
  },
  router: {
    middleware: ['lower-case-url'],
    trailingSlash: false,
    extendRoutes(routes, resolve) {
      routes.unshift({
          path: '/ru',
          components: {
            default: resolve(__dirname, 'pages/_.vue')
          },
          name: "index___ru",
        },

        {
          path: '/cn',
          components: {
            default: resolve(__dirname, 'pages/_.vue')
          },
          name: "index___cn",
        },
        {
          path: '/tr',
          components: {
            default: resolve(__dirname, 'pages/_.vue')
          },
          name: "index___tr",
        },
        {
          path: '/es',
          components: {
            default: resolve(__dirname, 'pages/_.vue')
          },
          name: "index___es",
        },
        {
          path: '/hi',
          components: {
            default: resolve(__dirname, 'pages/_.vue')
          },
          name: "index___hi",
        },
      );
    }
  },
  redirect,
  head: {
    title: 'Grizzly SMS',
    htmlAttrs: {
      lang: DEFAULT_LOCALE,
    },

    meta: [
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=1'},
      {hid: 'metadescription', name: 'description', content: ''},
      {name: "yandex-verification", content: "da025be989781d0e"},
      {name: "verification", content: "13a5535b595fd9f5e4892f35701d50"},
      {name: "build-version", content: version},
      {name: 'msapplication-TileColor', content: '#da532c'},
      {name: 'theme-color', content: '#ffffff'},
    ],
    link: [
      {rel: 'icon', type: 'image/x-icon', href: '/favicon.ico'},
      {rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png'},
      {rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png'},
      {rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png'},
      {rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5'},

      {rel: 'preload', as: 'image', href: '/logos/logo-light-v1.svg'},

      {rel: 'stylesheet', href: '/fonts/fonts.css'},
      {rel: 'preload', as: 'font', type: 'font/woff2', href: '/fonts/Manrope-Regular.woff2', crossorigin: true},
      {rel: 'preload', as: 'font', type: 'font/woff2', href: '/fonts/Manrope-Bold.woff2', crossorigin: true},
      {rel: 'preload', as: 'font', type: 'font/woff2', href: '/fonts/Manrope-Medium.woff2', crossorigin: true},
      // {rel: 'preload', as: 'font', type: 'font/woff2', href: '/fonts/Manrope-SemiBold.woff2', crossorigin: true}
    ],
    style: [
      {type: 'text/css', cssText: criticalFonts()},
      {type: 'text/css', cssText: criticalCss()},
    ],
    script: []
  },

  styleResources: {
    css: [
      // '~/assets/scss/components/critical-fonts.js',
      '~/assets/scss/components/critical-css.js',
    ],
  },

  // Global CSS: https://go.nuxtjs.dev/config-css
  css: [
    // '/fonts/fonts.css',
    '~/assets/scss/main.scss',
  ],

  // Plugins to run before rendering page: https://go.nuxtjs.dev/config-plugins
  plugins: [
    {src: '~plugins/initialFpPromise.js', mode: 'client'},
    {src: '~plugins/initialClientPlugin.js', mode: 'client'},
    {src: '~plugins/initialSourcebuster.js', mode: 'client'},

    '~plugins/toast.js',
    '~plugins/vue-mask.js',
    '~plugins/case-words.js',
    '~plugins/date-format.js',
    '~plugins/vue-js-modal.js',
    '~plugins/number-format.js',
    '~plugins/yandex-commerce.js',
    '~plugins/convertCurrency.js',
    '~plugins/hreflangInitial.js',
    '~plugins/default-country-selection.js',
    '~/node_modules/sitis-nuxt/plugins/sitis-img.js',

    '~plugins/vue-vuelidate.js'
  ],

  // Modules for dev and build (recommended): https://go.nuxtjs.dev/config-modules
  buildModules: [
    '@nuxt/typescript-build',
    '@nuxtjs/tailwindcss',

    ['@nuxtjs/pwa', {
      workbox: {enabled: false}
    }],
  ],

  image: {
    providers: {
      imageProvider: {
        name: 'imageProvider',
        provider: `${__dirname}/providers/imageProvider`,
        options: {
          modifiers: {
            format: 'webp'
          },
          baseURL: BASE_API_URL + 'storage/image/'
        }
      }
    },
    provider: 'imageProvider'
  },
  // Modules: https://go.nuxtjs.dev/config-modules
  modules: [
    ['@nuxtjs/redirect-module', {statusCode: 301}],
    ['vue-scrollto/nuxt', {duration: 500, offset: -140}],
    'cookie-universal-nuxt',
    '@nuxt/image',
    '@nuxtjs/style-resources',
    '@nuxtjs/axios',
    '@nuxtjs/i18n',
    ['~/node_modules/sitis-nuxt/lib', {
      baseUrl: BASE_API_URL
    }],

    // reCAPTCHA
    ['@nuxtjs/recaptcha', {
      siteKey,
      version: 2,
    }],

    // Yandex Metrika
    [
      '@rkaliev/nuxtjs-yandex-metrika',
      {
        id: 86626883,
        webvisor: false,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        ecommerce: "dataLayer",
        disabled: Boolean(process.env.ENVIRONMENT !== 'production'),
      },
    ]
  ],
  // gtm: {
  //   id: 'GTM-MV5K43Q',
  //   scriptDefer: true,
  //   pageTracking: true,
  // },
  // '@nuxtjs/axios'
  axios: {
    baseUrl: BASE_API_URL,
  },
  loading: {
    color: 'orange',
    height: '5px',
    failedColor: 'red',
    duration: 5000,
    continuous: true,
  },
  moment: {
    locales: ['ru'],
    timezone: true
  },
  // Build Configuration: https://go.nuxtjs.dev/config-build
  build: {
    extend(config, {isClient}) {
      // Extend only webpack config for client-bundle

      if (isClient) {
        config.devtool = 'source-map';
      }
    },
    filenames: {
      img: ({isDev}) => (isDev ? `[path][name].[ext]?v=${version}` : `img/[name].[contenthash:7].[ext]`),
      app: ({isDev, isModern}) => (isDev ? `[name]${isModern ? '.modern' : ''}.js?v=${version}` : `[contenthash:7]${isModern ? '.modern' : ''}.js`),
      chunk: ({isDev, isModern}) => (isDev ? `[name]${isModern ? '.modern' : ''}.js?v=${version}` : `[name]-[contenthash:7]${isModern
        ? '.modern' : ''}.js`),
      font: ({isDev}) => (isDev ? '[path][name].[ext]' : 'fonts/[name].[ext]'),
      css: ({isDev}) => (isDev ? `[name].css?v=${version}` : `css/[contenthash:7].css`),
    },
    extractCSS: false,
    optimization: {
      runtimeChunk: 'single',
      splitChunks: {
        chunks: 'all',

        maxInitialRequests: Infinity,
        minSize: 0,

        cacheGroups: {
          vendor: {
            test: /[\\/]node_modules[\\/]/,
            name(module) {
              const packageName = module.context.match(/[\\/]node_modules[\\/](.*?)([\\/]|$)/)[1];
              return `npm.${packageName.replace('@', '')}`;
            },
          },
        },
      },
    },
  },

  render: {
    bundleRenderer: {
      shouldPreload: (file, type) => ['font', 'style'].includes(type),
    }
  },

  cache: {
    // if you're serving multiple host names (with differing
    // results) from the same server, set this option to true.
    // (cache keys will be prefixed by your host name)
    // if your server is behind a reverse-proxy, please use
    // express or whatever else that uses 'X-Forwarded-Host'
    // header field to provide req.hostname (actual host name)
    useHostPrefix: false,
    pages: [
      // these are prefixes of pages that need to be cached
      // if you want to cache all pages, just include '/'
      '/',
    ],

    key(route, context) {
      // custom function to return cache key, when used previous
      // properties (useHostPrefix, pages) are ignored. return
      // falsy value to bypass the cache
    },

    store: {
      type: 'memory',

      // maximum number of pages to store in memory
      // if limit is reached, least recently used page
      // is removed.
      max: 100,

      // number of seconds to store this page in cache
      ttl: 60,
    },
  },

  // pwa: {
  //   manifest: {
  //     name: 'GrizzlySMS',
  //     lang: 'ru',
  //     useWebmanifestExtension: false
  //   }
  // },
  i18n: {
    detectBrowserLanguage: false,
    useRedirectCookie: false,
    baseURL: BASE_URL,
    langDir: '~/locales/',
    locales,
    useCookie: false,
    defaultLocale: DEFAULT_LOCALE,
    strategy: process.env.IS_DIFFERENT_DOMAINS === '1' ? 'no_prefix' : 'prefix_except_default',
    differentDomains: process.env.IS_DIFFERENT_DOMAINS === '1'
  },

  env: {
    isDev: process.env.ENVIRONMENT !== 'production',
    baseApiUrl: process.env.BASE_API_URL,
    versionLanguageService: process.env.VERSION_LANGUAGE_SERVICE || "0",
    authorizeUserAfterRegistration: process.env.AUTHORIZA_USER_AFTER_REGISTARION || "0",
    activeRentFunctional: process?.env?.ACTIVE_RENT_FUNCTIONAL || "0"
  }
}

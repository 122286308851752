export const redirect = [

  {from: '^/en/(.*)$', to: '/$1'},
  {from: '^/en/', to: '/'},
  {from: '^/en', to: '/'},

  {
    // eslint-disable-next-line
    from: '(?!^\/$|^\/[?].*$)(.*\/[?](.*)$|.*\/$)',
    to: (from : any, req : any) => {
      const base = String(req?._parsedUrl?.pathname || '').replace(/\/$/, '');
      const search = String(req?._parsedUrl?.search || '').replace(/\/$/, '');
      return base + (search != null ? search : '');
    }
  }
];




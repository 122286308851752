//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}

export const sortServiceList = (array, currentSort) => {
  const key = currentSort.replace('-', '');
  const sortOrder = currentSort.startsWith('-') ? -1 : 1;

  if (key === 'name') {
    if (sortOrder === -1) {
      return array.sort((a, b) => {
        return a[key].localeCompare(b[key]);
      });
    }
    return array;
  }
  if (key === 'count') {
    return array.sort((a, b) => {
      const comparison = a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0;

      return comparison * sortOrder;
    });
  }
  return array.sort((a, b) => {
    const comparison = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;

    return comparison * sortOrder;
  });
};


export default (props, inject) => {
  // Порядок слов [1, 2, 5]
  // 1 - Яблоко
  // 2 - Яблока
  // 5 - Яблок
  // Пример обращения this.$caseWords(1, ['час', 'часа', 'часов']) -> на выходе получим "час"

  const caseWords = function (number, words) {
    number = Math.abs(number)
    if (Number.isInteger(number)) {
      const cases = [2, 0, 1, 1, 1, 2]
      return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
    }
    return words[1]
  }
  inject('caseWords', caseWords)
}

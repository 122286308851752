export default (props, inject) => {
  const defaultCountrySelection = function () {
    const locale = props.app?.i18n?.locale || "en";
    const appVars = props?.store?.state?.appState?.vars;
    const languagesDefault = JSON.parse(appVars?.['Страна выбора по умолчанию (мультиязычный)'] || "{}");
    const languageDefault = appVars?.['Страна выбора по умолчанию'] || "46";

    if (Object.keys(languagesDefault).length <= 0) {
      return languageDefault
    }

    return languagesDefault?.[locale] || languageDefault
  }
  inject('defaultCountrySelection', defaultCountrySelection)
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function () {
    return {
      search: ""
    }
  },

  props: {
    list: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    items: function () {
      if (!this.search) {
        return [...this.list]
      }

      const search = String(this.search || "").toLowerCase();
      return [...this.list].filter((t) => {
        const name = (t?.name || '').toLowerCase();
        return name.includes(search);
      })
    }
  },

  methods: {
    changeValue: function (item) {
      this.$emit('change', item)
    },
  }
}

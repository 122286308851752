const getSelectedCountryId = ({ self }, context) => {
  const cookiesSelectedCountryId = self.$cookies.get("selectedCountryId");
  if (typeof cookiesSelectedCountryId == "undefined") {
    return null
  }

  let currentCountryId = cookiesSelectedCountryId;
  if (typeof currentCountryId === "undefined" || currentCountryId === null) {
    return null
  }

  return Number(currentCountryId)
}

export default getSelectedCountryId

import { render, staticRenderFns } from "./FormBlock.vue?vue&type=template&id=a785ffdc&"
import script from "./FormBlock.vue?vue&type=script&lang=js&"
export * from "./FormBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Feedback: require('/var/www/7grizzlysms.com/release-2025.01.21-13:54:46/components/feedback/Feedback.vue').default})

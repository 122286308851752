//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";

export default BaseCmsBlock.extend({
  name: "HowWorks",

  computed: {
    title: function () {
      return this.values?.title || ""
    },
    message: function () {
      return this.values?.text || ""
    },

    items: function () {
      return this.values?.items || []
    },
  }
});

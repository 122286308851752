export default (context, inject) => {
  const formatDate = function (unixDate) {

    // определяем языковую локаль или выставялем дефолтную (en)
    const locale = context?.i18n?.locale || context?.i18n?.defaultLocale;

    // переводим unix дату в обычную
    const date = new Date(unixDate * 1000);

    // определяем нужно ли заполнять 0 месяц и день (для китая не нужно)
    const countPadStart = Boolean(locale === 'cn') ? 1 : 2;

    const dd = `${date.getDate()}`.padStart(countPadStart, '0');
    const mm = `${date.getMonth() + 1}`.padStart(countPadStart, '0');
    const yyyy = `${date.getFullYear()}`;

    if (locale === 'ru' || locale === 'tr') {
      return `${dd}.${mm}.${yyyy}`;
    }
    if (locale === 'en') {
      return `${mm}-${dd}-${yyyy}`;
    }
    if (locale === 'cn') {
      return `${yyyy}年${dd}月${mm}`;
    }
  };
  inject ('formatDate', formatDate);
};

export const criticalFonts = () => {
  return `
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      url(/fonts/Manrope-Regular.woff2) format('woff2'),
      url(/fonts/Manrope-Regular.woff) format('woff');
  }

  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src:
      url(/fonts/Manrope-Medium.woff2) format('woff2'),
      url(/fonts/Manrope-Medium.woff) format('woff');
  }

  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
      url(/fonts/Manrope-SemiBold.woff2) format('woff2'),
      url(/fonts/Manrope-SemiBold.woff) format('woff');
  }

  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
      url(/fonts/Manrope-Bold.woff2) format('woff2'),
      url(/fonts/Manrope-Bold.woff) format('woff');
  }
`
}

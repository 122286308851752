//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";

export default BaseCmsBlock.extend({
  name: "TwoColumnsBlock",
  data() {
    const gridCols = this.extras.totalCols;
    const gap = this.values.gap;
    return {
      gridCols,
      gap,
    };
  },
  computed: {
    setGridAndGap() {
      let _classes = [
        this.setGridCols(this.gridCols),
        this.setGap(this.gap)
      ];
      if (String(this.$route.path || '').includes('/faq')) {
        _classes.push('mb-flex-col-reverse')
      }

      return _classes.join(' ');
    },
  },
  methods: {
    setGridCols(gridCols) {
      if (gridCols > 11) {
        gridCols = gridCols - Math.floor(gridCols / 12) * 12;
      }
      if (gridCols % 12 === 0) return "lg:grid-cols-12";
      if (gridCols % 11 === 0) return "lg:grid-cols-11";
      if (gridCols % 10 === 0) return "lg:grid-cols-10";
      if (gridCols % 9 === 0) return "lg:grid-cols-9";
      if (gridCols % 8 === 0) return "lg:grid-cols-8";
      if (gridCols % 7 === 0) return "lg:grid-cols-7";
      if (gridCols % 6 === 0) return "lg:grid-cols-6";
      if (gridCols % 5 === 0) return "lg:grid-cols-5";
      if (gridCols % 4 === 0) return "lg:grid-cols-4";
      if (gridCols % 3 === 0) return "lg:grid-cols-3";
      if (gridCols % 2 === 0) return "lg:grid-cols-2";
      if (gridCols % 1 === 0) return "lg:grid-cols-1";
    },
    setGap(gap) {
      if (gap > 20) {
        gap = gap - Math.floor(gap / 21) * 21;
      }
      if (gap % 20 === 0) return "lg:gap-20";
      if (gap % 18 === 0) return "lg:gap-18";
      if (gap % 16 === 0) return "lg:gap-16";
      if (gap % 14 === 0) return "lg:gap-14";
      if (gap % 12 === 0) return "lg:gap-12";
      if (gap % 11 === 0) return "lg:gap-11";
      if (gap % 10 === 0) return "lg:gap-10";
      if (gap % 9 === 0) return "lg:gap-9";
      if (gap % 8 === 0) return "lg:gap-8";
      if (gap % 7 === 0) return "lg:gap-7";
      if (gap % 6 === 0) return "lg:gap-6";
      if (gap % 5 === 0) return "lg:gap-5";
      if (gap % 4 === 0) return "lg:gap-4";
      if (gap % 3 === 0) return "lg:gap-3";
      if (gap % 2 === 0) return "lg:gap-2";
      if (gap % 1 === 0) return "lg:gap-1";
    },
    setLeftColWidth(idx) {
      if (idx > 12) {
        idx = idx - Math.floor(idx / 13) * 13;
      }
      if (idx % 12 === 0) return "lg:col-span-12";
      if (idx % 11 === 0) return "lg:col-span-11";
      if (idx % 10 === 0) return "lg:col-span-10";
      if (idx % 9 === 0) return "lg:col-span-9";
      if (idx % 8 === 0) return "lg:col-span-8";
      if (idx % 7 === 0) return "lg:col-span-7";
      if (idx % 6 === 0) return "lg:col-span-6";
      if (idx % 5 === 0) return "lg:col-span-5";
      if (idx % 4 === 0) return "lg:col-span-4";
      if (idx % 3 === 0) return "lg:col-span-3";
      if (idx % 2 === 0) return "lg:col-span-2";
      if (idx % 1 === 0) return "lg:col-span-1";
    },
    setRightColWidth(idx) {
      if (idx > 12) {
        idx = idx - Math.floor(idx / 13) * 13;
      }
      if (idx % 12 === 0) return "lg:col-span-12";
      if (idx % 11 === 0) return "lg:col-span-11";
      if (idx % 10 === 0) return "lg:col-span-10";
      if (idx % 9 === 0) return "lg:col-span-9";
      if (idx % 8 === 0) return "lg:col-span-8";
      if (idx % 7 === 0) return "lg:col-span-7";
      if (idx % 6 === 0) return "lg:col-span-6";
      if (idx % 5 === 0) return "lg:col-span-5";
      if (idx % 4 === 0) return "lg:col-span-4";
      if (idx % 3 === 0) return "lg:col-span-3";
      if (idx % 2 === 0) return "lg:col-span-2";
      if (idx % 1 === 0) return "lg:col-span-1";
    },
  },
});

import {ymGoalCheck, ymSendUserID, ymSendUserInfo} from "~/helpers/ym_helper";

export default {
  methods:{
    yandexGoalCheck(goal){
      ymGoalCheck({
        ym_number:86626883,
        ym_goal:goal
      })
    },

    yandexSendUserInfo({userId, userEmail}={} ){
      ymSendUserInfo({
        ym_number:86626883,
        userId: userId || '',
        userEmail: userEmail || '',
      })
    },

    yandexSendUserID({userId}={}){
      ymSendUserID({
        ym_number:86626883,
        userId:userId || '',
      })
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from '../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin'

export default BaseCmsBlock.extend({
  name: "VideoBlock",

  data: function () {
    return {
      isClick: false,
      screenWidth: 0,
    }
  },

  mounted() {
    this.screenWidth = window.innerWidth;
  },

  computed: {
    title: function () {
      return this.values?.title || ""
    },

    imageId: function () {
      return this.screenWidth <= 986 ? (this.values?.image_mobile || 0) : (this.values?.image || 0)
    },
    imageWidth: function () {
      const isServer = Boolean(process.server);
      if (isServer) {
        return 720
      }

      const innerWidth = window.innerWidth;
      if (innerWidth >= 990) {
        return 720
      }

      let _width = (window.innerWidth || 0) - 30;
      if (window.innerWidth >= 990) {
        _width = _width - 48;
      }
      if (window.innerWidth < 990 && window.innerWidth >= 660) {
        _width = _width - 32;
      }
      if (window.innerWidth < 660) {
        _width = _width - 16;
      }

      return _width
    },
    getContent: function () {
      return this.values?.video_html || '';
    },

    imagePlaceholderUrl: function () {
      const imageId = String(this.values?.image);
      return this.$img(imageId, {
        width: 720,
        fit: "crop",
        q: 1, blur: 1,
      })
    },
  },

  methods: {
    playVideo: function () {
      this.isClick = true;
    }
  }
});

function paymentCookiesHandler(app) {
  const payment = app.$cookies.get('payment-test');

  if (payment === undefined || payment === null) {
    // app.$cookies.set('payment-test', Boolean(Math.round(Math.random())), {
    app.$cookies.set('payment-test', Boolean(true), {
      expires: new Date(Date.now() + 3600 * 60 * 60 * 1000),
      path: '/',
    });
  }
}

export default function (app) {
  paymentCookiesHandler(app);
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {themeChange} from "theme-change";
import CurrencyToggle from "~/components/common/header/CurrencyToggle";
import LangToggle from "~/components/common/header/LangToggle";
import NavigationPanel from '~/components/profile/NavigationPanel.vue';

export default {
  data() {
    return {
      isFixed: false,
      scrlElem: null,
      isShowSearch: false
    };
  },

  components: {
    LangToggle,
    NavigationPanel,
    CurrencyToggle,

    Search: () => import('~/components/common/Search.vue'),
    HeaderMobile: () => import('~/components/layouts/HeaderMobile.vue')
  },

  props: {
    menu: {
      type: Array,
    },
    isLoggedIn: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  computed: {
    hreflang() {
      return this?.$i18n?.locale || this?.$i18n?.defaultLocale || "en";
    },
    getLogoPath() {
      return `/${this.hreflang === 'en' ? '' : this.hreflang}`;
    },

    isMobileTemplate: function () {
      if (Boolean(process.server)) {
        return Boolean(this.$store.state?.["local-settings"]?.viewport === 'mobile');
      }

      const isWidthMobile = Boolean(process.server) ? false : Boolean(window.innerWidth <= 1023);
      return Boolean(this.$store.state?.["local-settings"]?.viewport === 'mobile' && isWidthMobile);
    },
  },

  mounted() {
    themeChange(false);
  },

  methods: {
    changeOpenSearch: function () {
      this.isShowSearch = !this.isShowSearch;
    }
  },
};

const getCanonical = (domain, path) => {
  let search = String(path || '').split("?")?.[1] || "";
  if (search) {
    search = search.split('&').filter((t) => !Boolean(t.includes('page=1'))).join('&')
  }

  const clearPath = path.split('/f/')[0] || "";
  let filtersString = path.split('/f/')[1] || "";
  let filtersObject = {};
  filtersString.split('/').filter((t) => !!t).map((filterString) => {
    let [key, values] = filterString.split("=");
    values = values.split(",");
    if (values.length <= 1) {
      filtersObject[key] = values.join(",");
    }
  });
  let filtersActive = Object.keys(filtersObject).map((key) => {
    return `${key}=${filtersObject[key]}`
  }).join('/');
  let pathFilter = [clearPath, filtersActive].filter((t) => !!t).join("/f/");
  pathFilter = pathFilter.split("?")?.[0] || "";

  if (search) {
    pathFilter = [pathFilter, search].join('?')
  }

  return ['https://', domain, pathFilter].join("");
}

export default getCanonical

export default (context, inject) => {

  const hreflangInitial = ()=>{
    const currentLang = context.i18n?.locale || context.i18n?.defaultLocale || "en";
    return transformLang(currentLang);
  }

  const transformLang=(lang)=>{
    switch (lang) {
      case 'cn':
        return 'zh';

      default:
        return lang;
    }
  }
  inject('hreflangInitial',hreflangInitial);
}

//
//
//
//

import LanguagesList from "~/components/profile/LanguagesList.vue";
export default {
  name: "ServiceInverseBlock",

  components: {
    LanguagesList
  }
}

const isFindMenuItem = (items, path) => {
  let ifFind = false;
  for (const index in items) {
    if (ifFind) {
      break;
    }

    const item = items[index];
    const itemChildren = item?.children || [];
    if (itemChildren.length > 0) {
      ifFind = isFindMenuItem(itemChildren, path);
      if (ifFind) {
        break
      }
    }
    let itemPath = String(['/', (item.path || item.alias)].join(''));
    ifFind = Boolean(String(path || "").includes(itemPath));
  }
  return ifFind
}
const checkCmsPageFromPath = (path, apiMenus) => {
  if (path === '/' || path === '') {
    path = '/homepage'
  }

  let isFind = false;
  for (const key in (apiMenus || {})) {
    if (isFind) {
      break;
    }

    const parentMenu = apiMenus[key];
    const parentMenuItems = parentMenu?.items || [];
    isFind = isFindMenuItem(parentMenuItems, (path || '/'));
  }

  return isFind
}

export {
  checkCmsPageFromPath
}

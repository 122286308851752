//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";
import YandexMetricaMixin from "~/mixin/YandexMetricaMixin";
import ServiceCard from "~/cms/blocks/country-service/ServiceCard"
import ElementLink from "~/cms/blocks/country-service/ElementLink"
import {UserMixin} from "../../node_modules/sitis-nuxt/lib/mixins";
import error from "~/layouts/error";

export default BaseCmsBlock.extend({
  name: "CountryServiceBlock",

  data() {
    return {
      isVisible: false,
      isLoading: false,
      priceList: this.extras?.offers || [],
    }
  },

  mixins: [
    UserMixin,
    YandexMetricaMixin
  ],

  mounted() {
    this.$nextTick(async () => {
      this.isVisible = this.checkDisplayNone();
    })
  },

  watch: {
    isVisible: async function () {
      await this.getServicesList();
    },
    isLoadingUser: async function (oldUser, newUser) {
      if (JSON.stringify(oldUser || {}) !== JSON.stringify(newUser || {})) {
        await this.getServicesList();
      }
    }
  },

  components: {
    ServiceCard,
    ElementLink
  },

  computed: {
    titleH2: function () {
      return this.values?.title || ""
    },

    getCurrencyUser() {
      return this.$store.getters['user/user'];
    },

    isUserLoading() {
      return this.$store.state.appState.loading?.user;
    },

    telegramLinksList() {
      return [
        {
          text: 'servicesList.telegramMessagePrice',
          title: 't.me/GrizzlySmsVIP',
          link: 'https://t.me/GrizzlySmsVIP'
        },
        {
          text: 'servicesList.telegramMessageBot',
          title: 't.me/GrizzlySMScom_bot/app',
          link: 'https://t.me/GrizzlySMScom_bot/app'
        },
        {
          text: 'servicesList.telegramMessageNews',
          title: 't.me/GrizzlySMS_News',
          link: 'https://t.me/GrizzlySMS_News'
        },
      ]
    },

    getMessage() {
      return this.values?.text || '';
    },
    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },

    servicesWithCountryCount: function () {
      const servicesWithCountry = [...this.extras?.offers || []];
      return new Array(servicesWithCountry.length).fill(1)
    },
  },

  methods: {
    async getServicesList() {
      if (this.isLoadingUser || !this.isVisible) {
        return
      }

      const isCustomPrice = Boolean(this.$store.state?.user?.user?.dynamicFields?.custom_price);
      if (!isCustomPrice) {
        return
      }


      const servicesWithCountry = [...this.extras?.offers || []];
      const body = {
        filter: servicesWithCountry.map(t => {
          return {
            "country_external_id": t.country_external_id,
            "service_external_id": t.service_external_id
          }
        }),
        "wholesale": this.values?.is_opt
      }
      const resUpdatePrice = await this.$api.agent.transport.post('service/get-countries-services-prices', body)
        .then((res) => {
          return res.data;
        })
        .catch(() => []);
      if (resUpdatePrice.length <= 0) {
        return
      }

      const priceList = [...(this.priceList || [])]
        .map((item) => {
          const price = resUpdatePrice.find((t) => Boolean(
            Boolean(String(t.country_external_id) === String(item.country_external_id)) &&
            Boolean(String(t.service_external_id) === String(item.service_external_id))
          ))?.price;

          return {
            ...item,
            price
          }
        })

      this.priceList = priceList;
    },
    async onBuyNumber(number) {
      const paymentTest = this.$cookies.get("payment-test");

      if (!this.isLoggedIn && paymentTest) {
        await this.$router.push(this.localePath(`/profile/pay?service=${number.service_external_id}&country=${number.country_external_id}`));
        return;
      }

      if (!this.isLoggedIn && !paymentTest) {
        await this.$router.push(this.localePath("/registration?goback=1"));
        return null;
      }

      this.isLoading = true;
      let countryId = number?.country_external_id;
      let numberId = number?.service_external_id;

      // Проверка баланса пользователя
      const balance = await this.$api.agent.transport.get("sms-users/balance")
        .then((res) => {
          return res.data
        }).catch(() => {
          return 0
        });
      if (paymentTest && balance < +number.price) {
        await this.$router.push(this.localePath(`/profile/pay?service=${number.service_external_id}&country=${number.country_external_id}`));
        return;
      }


      if (!paymentTest && balance < +number.price) {
        await this.$router.push(this.localePath("/profile/pay"));
        return
      }

      this.yandexGoalCheck('buy_popular-number-home_attempt');
      this.yandexSendUserInfo({
        userId: this?.user?.id,
        userEmail: this?.user?.email
      })

      const newNumber = await this.$api.agent.transport
        .get(`sms-users/get-number/${countryId}/${numberId}`)
        .catch((err) => {
          let error = JSON.parse(err?.request?.response);
          this.$toast.error(error.message || "Error");
          this.isLoading = false;
        });

      if (!newNumber) {
        this.isLoading = false;
        return null;
      }

      if (this.$route.path !== "/profile") {
        await this.$router.push(this.localePath("/profile"));
      }

      this.yandexGoalCheck('buy_popular-number-home_success');
      this.yandexSendUserID({
        userId: this?.user?.id,
      })

      this.isLoading = false;
      this.$store.commit("active-numbers/updateIndex");
    },

    checkDisplayNone: function () {
      return !Boolean(
        this.$refs?.countryServiceBlock?.clientHeight <= 0 ||
        this.$refs?.countryServiceBlock?.clientWidth <= 0
      )
    },
  },
})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";

export default BaseCmsBlock.extend({
  name: "AboutBlock",

  data: function () {
    return {
      isMore: false,
      isHidden: true,
    }
  },

  computed: {
    buttonLabel: function () {
      const keyTranslate = Boolean(this.isMore) ? 'aboutBlock.rollUp' : 'aboutBlock.showMoreBtn'
      return this.$t(keyTranslate)
    }
  },

  mounted: function () {
    this.inisState();
  },

  methods: {
    inisState: function () {
      const messageChildren = this.$refs.refMessage?.children || [];

      let fullHeightChildren = 0;
      for (const key in messageChildren) {
        const _element = messageChildren[key];
        const _elementHeight = Boolean(_element?.getBoundingClientRect) ? _element.getBoundingClientRect()?.height || 0 : 0;
        fullHeightChildren = fullHeightChildren + _elementHeight;
      }

      if (fullHeightChildren > 300) {
        return
      }
      this.isMore = true;
      this.isHidden = false;
    },
    changeOpen: function () {
      this.isMore = !this.isMore;
    },
  }
});

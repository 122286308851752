const utilsGetOgUrl = ({ context }) => {
  const isServer = Boolean(process.server);
  let host = String(isServer ? context.$nuxt.context.req.headers.host : window.location.host);
  if (!host.includes('https://')) {
    host = ['https://', host].join('')
  }

  let fullPath = context.$route.fullPath === '/' ? '' : context.$route.fullPath;
  const locale = context.$i18n?.locale || context.$i18n?.defaultLocale;
  const isUseLocaleCode = fullPath.includes(`/${ locale }`);
  fullPath = String(fullPath || '').split(`/${ locale }`).pop() || '';

  let preliminary = String([host, fullPath].join(isUseLocaleCode ? `/${ locale }` : '') || '');
  if (preliminary.includes('?')) {
    preliminary = String(preliminary.split('?')?.[0]);
  }

  return preliminary
}

export default utilsGetOgUrl

const scrollToElement = (identificatory, params = {}) => {
  const element = document.querySelector(identificatory);
  if (!element) {
    return
  }

  const scrollContainer = document.getElementById('scroll-container');
  const parentTop = element.getBoundingClientRect().top + scrollContainer.scrollTop;
  const headerHeight = document.querySelector('#siteHeader')?.getBoundingClientRect().height || 0;

  let topPosition = parentTop - headerHeight;
  if (params?.offseetTop) {
    topPosition = topPosition - params?.offseetTop;
  }

  scrollContainer.scrollTo({ top: topPosition });
}
export {
  scrollToElement
}

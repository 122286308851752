// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/svg/common/loading.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout>.breadcrumbs{display:none}.layout h1{margin-bottom:1rem;text-align:center;font-size:1.25rem;line-height:1.75rem;font-weight:600}@media (min-width: 660px){.layout h1{margin-bottom:2rem}.layout h1{font-size:28px}}.service-favorite-countries__list{display:flex;flex-wrap:wrap;margin-top:-10px;margin-left:-10px}.service-favorite-countries__list>*{width:calc(50% - 10px);margin-top:10px;margin-left:10px}.service-favorite-country-disabled{-webkit-backdrop-filter:blur(1.5px);backdrop-filter:blur(1.5px);font-weight:600}.service-favorite-country-card{padding:4px 8px;box-sizing:border-box;border-radius:8px;border:1px solid;position:relative;--tw-border-opacity:1;border-color:hsla(var(--s) / var(--tw-border-opacity));--tw-bg-opacity:1;background-color:hsla(var(--s) / var(--tw-bg-opacity))}.service-favorite-country-card,.service-favorite-country-card__country{display:flex;align-items:center}.service-favorite-country-card__country img{width:32px;height:24px;margin-right:8px}.service-favorite-country-card__price{margin-left:auto;padding-right:30px;min-width:101px}.service-favorite-country-card__price .--loading{position:relative;color:transparent!important}.service-favorite-country-card__price .--loading:after{content:\"\";position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:30px;height:30px;background-size:contain;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.service-favorite-country-card__action button{height:auto;min-height:auto;padding:4px 8px;font-size:14px;line-height:16px}@media(max-width:1299px){.service-favorite-countries__list>*{width:calc(100% - 10px)}}@media(max-width:989px){.service-favorite-countries__list>*{width:calc(50% - 10px)}}@media(max-width:860px){.service-favorite-countries__list>*{width:calc(100% - 10px)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

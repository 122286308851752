export default (props, inject) => {
  const _generateId = function () {
    return [
      (Math.random() + 1).toString(36).substring(7),
      (Math.random() + 1).toString(36).substring(7),
      (Math.random() + 1).toString(36).substring(7),
    ].join("_")
  };

  const show = function (message, options) {
    props.store.commit("toast/addItem", {
      type: "show",
      id: _generateId(),
      message: message || "",
      options: options || null
    });
  };
  const info = function (message, options) {
    console.log('props.store: ', props.store);
    props.store.commit("toast/addItem", {
      type: "info",
      id: _generateId(),
      message: message || "",
      options: options || null
    });
  };
  const error = function (message, options) {
    props.store.commit("toast/addItem", {
      type: "error",
      id: _generateId(),
      message: message || "",
      options: options || null
    });
  };
  const success = function (message, options) {
    props.store.commit("toast/addItem", {
      type: "success",
      id: _generateId(),
      message: message || "",
      options: options || null
    });
  };

  inject("toast", {
    info,
    show,
    error,
    success,
  });
}

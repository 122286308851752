export const state = () => ({
  link: {}
})

export const mutations = {
  setLink(state, link) {
    state.link = link;
  }
}


import Cookie from 'cookie'
import { Ecom } from 'sitisjs/dist/index'
import { locales } from '../nuxt.config';
import storeModules from '/var/www/7grizzlysms.com/release-2025.01.21-13:54:46/node_modules/sitis-nuxt/lib/store'

const setCookie = function (cookieName, cookieValue, expires) {
	let domain = ['', window.location.hostname.split('.').reverse()[1], window.location.hostname.split('.').reverse()[0]].join('.');
	if (domain.includes('..')) {
		domain = domain.replace('..', '.');
	}
	document.cookie = `${cookieName}=${cookieValue}; expires=${expires}; domain=${ domain }; path=/`;
}

export default (app, inject) => {
	const { isServer, req, store } = app;

	const host = Boolean(process.client) ? window.location.host : req?.headers?.host;
	const isLocalHost = Boolean((host || "").indexOf("localhost:") > -1);

	const options = {"baseUrl":"https:\u002F\u002F7grizzlysms.com\u002Fapi\u002F","storePath":"\u002Fvar\u002Fwww\u002F7grizzlysms.com\u002Frelease-2025.01.21-13:54:46\u002Fnode_modules\u002Fsitis-nuxt\u002Flib\u002Fstore"}

		Object.keys(storeModules).forEach(ns => {
			app.store.registerModule(ns, storeModules[ns])
		})

	const getHeaders = () => {
		if (typeof document === 'object' && typeof document.cookie === 'string') {
			return document.cookie || ''
		}
		return req.headers.cookie || ''
	}

	const cookies = Cookie.parse(getHeaders());

	let sessionToken = cookies['sessionToken'];
  if (process.client && !sessionToken) {
    sessionToken = `frnt${(Math.random() + 1).toString(36).substring(3)}`;
    var expires = new Date(new Date().setFullYear(new Date().getFullYear() + 5));
    setCookie('sessionToken', sessionToken, expires);
  }

	const authToken = cookies.authToken || null;

	const config = {
		baseUrl: options.baseUrl,
		headers: {
		},
	}
	const headers = {};

	if (authToken) {
		config.headers['Authorization'] = `Bearer ${authToken}`;
		headers.authToken = authToken;
	}
	if (sessionToken) {
		config.headers['x-session-token'] = sessionToken;
		headers.sessionToken = sessionToken;
	}

	const locale = app.route.name?.split('___').pop();
	if (locales && locales.some((l) => l.code === locale)) {
		config.headers['x-user-locale'] = locale
	}

	if (req) {
		config.headers['user-agent'] = req.headers['user-agent'];
	}
	if (req && !isLocalHost) {
		config.headers['x-forwarded-host'] = req.headers['host'];
		config.headers['x-forwarded-for'] = req.headers?.['x-forwarded-for'] || "";
	}

	// Устоновка x-forwarded-host
	if (isServer && req && !isLocalHost) {
		config.headers['x-forwarded-host'] = req?.headers?.['host'] || "";
		headers.forwardedHost = req?.headers?.['host'] || "";

		if (Boolean(req?.headers?.['x-forwarded-for'] || "")) {
			config.headers['x-forwarded-for'] = req?.headers?.['x-forwarded-for'] || "";
			headers.forwardedFor = req?.headers?.['x-forwarded-for'] || "";
		}
	}
	if (!isServer && process.browser && !isLocalHost) {
		config.headers['x-forwarded-host'] = window?.location?.host || "";
		headers.forwardedHost = window?.location?.host || "";
	}

  if (!process.client) {
    const originHost = `https://${ req?.headers?.['host'] || "" }`;
    headers.origin = originHost;
    config.headers['origin'] = originHost;
  }

	const api = new Ecom(config);

	// Установка дополнительных ключей
	Object.keys((headers || {})).map((key) => {
		api[key] = headers[key];
	});

	inject('api', api)
}

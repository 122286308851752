//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    openOffer: async function (offer, section) {
      this.$emit("openOffer", {
        ...offer,
        type: section.type,
        country: section.country,
        service: section.service,
      });
    },

    getPriceOffer: function (offer) {
      const currentCurrency = this.$store?.state?.currency?.currentCurrency;

      const minCount = offer?.min_count || 1;
      const priceVal = offer?.price || 0;
      const price = this.$convertCurrency(priceVal * minCount);

      const symbol = Boolean(currentCurrency === 'usd') ? "$" : "₽";

      return [price, symbol].join(" ")
    },
    getNameOffer: function (offerName) {
      return offerName
      const locale = this.$i18n.localeProperties.code;
      return offerName?.[locale] || offerName?.en || offerName?.ru || offerName || ""
    },
  }
}

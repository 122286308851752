const middleware = {}

middleware['checkAuth'] = require('../middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['getLanguage'] = require('../middleware/getLanguage.js')
middleware['getLanguage'] = middleware['getLanguage'].default || middleware['getLanguage']

middleware['lower-case-url'] = require('../middleware/lower-case-url.js')
middleware['lower-case-url'] = middleware['lower-case-url'].default || middleware['lower-case-url']

export default middleware

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    slide: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    clientOnly: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    rootComponent: function () {
      const link = String(this.slide?.link || '');
      if (!link) {
        return "div"
      }
      const isExternalLink = Boolean(link.includes('https://') || link.includes('http://'));
      if (isExternalLink) {
        return "a"
      }
      return "nuxt-link"
    },

    imageSrc: function () {
      if (Boolean(String(this.slide?.is_gif) === '1')) {
        return String(this.slide?.image_link)
      }
      return String(this.slide?.image)
    },
    imageComponent: function () {
      if (Boolean(String(this.slide?.is_gif) === '1')) {
        return "img"
      }
      return "sitis-img"
    },

    imageWidthSize: function () {
      const isServer = Boolean(process.server);
      if (isServer) {
        return 782
      }

      const innerWidth = document?.body?.clientWidth || window?.innerWidth || 1024;
      if (innerWidth >= 1024) {
        return 782
      }

      let _width = (innerWidth || 0);
      if (innerWidth >= 990) {
        _width = _width - 48;
      }
      if (innerWidth < 990 && innerWidth >= 660) {
        _width = _width - 32;
      }
      if (innerWidth < 660) {
        _width = _width - 16;
      }

      return Math.ceil(_width - 38)
    },
    imagePlaceholderUrl: function () {
      if (Boolean(String(this.slide?.is_gif) === '1')) {
        return ''
      }
      const imageId = String(this.slide?.image);
      return this.$img(imageId, {
        width: 640,
        fit: "crop",
        q: 1, blur: 1,
      })
    },
  }
}

export const state = () => ({

    queryString: '',


    services: [],

    pagination: {
        page: 1,
        total: 1,
        isVisibleLoadMore: true,
    },

    isWholesale: false,
    isLoading: true

});

export const mutations = {
    setListServices(state, services) {
        state.services = services;
    },

    setLoading(state, isLoading) {
        state.isLoading = isLoading;
    },

    onSearchServices(state, queryStr) {
        state.queryString = queryStr;
        state.pagination.page = 1;
    },

    updatePagination(state, newPagination) {
        state.pagination = {...newPagination};
    },

    isWholesaleToggle(state, isWholesale) {
        state.isWholesale = isWholesale;
    },


};

export const actions = {
    async getListServices({commit, state, rootState}, props = {}) {
        const {isLoadMore = false, isResetPagination = false, extraQueryParams = ''} = props;
        let userId = rootState?.user?.user?.id ? rootState?.user?.user?.id : 'guest';
        if (!rootState?.user?.user?.dynamicFields?.custom_price) {
          userId = 'guest';
        }
        const queryStr = state?.queryString ? `&query=${state?.queryString}` : '';
        const isWholesale = state.isWholesale ? 1 : 0;

        if (isResetPagination) {
            commit("updatePagination", {
                ...state.pagination,
                page: 1,
            })
        }

        commit("setLoading", true);
        let currentCountryDefoultId = this.$defaultCountrySelection();
        // Получение текущией выбранной страны
        const cookiesSelectedCountryId = this.$cookies.get("selectedCountryId");
        let currentCountryId = (typeof cookiesSelectedCountryId == "undefined") ? currentCountryDefoultId : cookiesSelectedCountryId;
        currentCountryId = Boolean(typeof currentCountryId === "undefined" || currentCountryId === null) ? "95" : String(currentCountryId);
        currentCountryId = +currentCountryId;

        let newServicesList = [...state.services];
        if (!isLoadMore) {
            newServicesList = [];
        }

        // Получение услуг по выбранной стране
        const response = await this.$api.agent.transport
            .get(`service/get-prices/${currentCountryId}?wholesale=${isWholesale}${extraQueryParams}&user=${userId}${queryStr}`)
            .then((res) => {
                return res
            })
            .catch(() => {
                return []
            });

        const headers = response.headers || {};
        newServicesList = [...newServicesList, ...(response?.data || [])];

        let pagination = {...state.pagination};

        pagination.total = +headers["x-pagination-page-count"];


        pagination.isVisibleLoadMore = Boolean(
            pagination.page !== pagination.total
        );
        commit("updatePagination", pagination)
        commit("setListServices", newServicesList);
        commit("setLoading", false);
    },

    async onLoadMore(props) {
        const {commit, state, dispatch} = props;
        if (state.isLoading) {
            return;
        }

        let pagination = {...state.pagination};

        pagination.page = pagination.page + 1;

        commit("updatePagination", pagination);

        dispatch('getListServices', {
            isLoadMore: true,
        });
    },

    async onSearchAction({dispatch, commit}, queryStr) {
        commit("onSearchServices", queryStr);

        dispatch('getListServices', {
            isLoadMore: false,
        });
    },

    async onToggleWholesale({dispatch, commit}, props = {}) {
        const {isWholesale} = props;

        commit('isWholesaleToggle', isWholesale);
        dispatch('getListServices', {
            isLoadMore: false,
            isResetPagination: true,
        });
    },

    async setCurrency({dispatch, commit}, props) {
        const {currency} = props;

        commit('setCurrency', currency);
        dispatch('getListServices', {
            isLoadMore: false,
        });
    }
};


export const getters = {
    isWholesale: ({isWholesale}) => isWholesale,
    queryString: ({queryString}) => queryString,
    isShowMore: ({pagination}) => pagination,
    services: ({services}) => services,
    isLoading: ({isLoading}) => isLoading,
}

//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "LinkToAccount",
  props: {
    accountName: {
      type: String,
      default: ''
    }
  },

}

export default (props, inject) => {
  // amount - число
  // decimalCount - сколько чисел после запятой
  // decimal - разделитель между целым и десятичным числом
  // thousands - разделитель между разрядами целого числа

  const numberFormat = function (amount, decimalCount = 0, decimal = ',', thousands = ' ') {
    if (decimalCount === 'auto') {
      decimalCount = (String(amount).split('.')?.[1] || '').length;
      if (decimalCount > 3) {
        decimalCount = 3
      }
    }

    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount), 10).toString();
    const j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j)
      .replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
        + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  };
  inject('numberFormat', numberFormat);
};

export const state = () => ({
    user: {},
})

export const mutations = {
    onSetUserAuth(state, statusAuth) {
        state.isUserAuth = statusAuth;
    },
    onSetUser(state, user) {
        state.user = user;
    },
}
export const actions = {
    async onSetUser({commit}) {
        try {
            const user = await this.$api.agent.transport.get('users/me', {
                headers: {
                    "Authorization": `Bearer ${this.$cookies.get("authToken")}`
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return false
            });

            if (user) {
                commit('onSetUser', user);
            }


        } catch (error) {
            console.error(error);
        }
    }
}

export const getters = {
    isUserAuth: ({user}) => Boolean(Object.keys(user).length),
    user: ({user}) => user,
}
